
import { getWindowSize, getWindowHeight } from '@/utils/utils.js'
// import { name } from '../../package'

export default {
  subAPPENName: 'operation',
  systemName: '',
  maxCacheNum: 20, // 路由组件最大缓存数量
  maxStarMenus: 20, // 收藏菜单的最大数量
  notifyDuration: 3000, // 通知消息组件显示时间
  screenSize: getWindowSize() < 1300 ? 'small' : 'default', // 屏幕尺寸，做页面适配
  screenHeight: getWindowHeight() < 800 ? 'small' : 'default',
  table: {
    pageNum: 1,
    pageSize: 10,
    serverSorter: false,
    serverFilter: false,
    pageSizes: [10, 20, 30, 40]
  },
  charts: {
    // 文字大小
    textSize: 12,
    // 文字颜色
    textColor: 'rgba(0, 0, 0, 0.65)',
    // 悬浮框背景颜色
    bgColor: 'rgba(255, 255, 255, 0.85)',
    // 柱状图/折线图鼠标经过的背景颜色
    barBgColor: 'rgba(0, 0, 0, 0.05)',
    // 盒子外发光效果
    boxShadow: '0 0 4px rgba(0, 0, 0, 0.35)',
    // 坐标轴颜色
    lineColor: 'rgba(0, 0, 0, 0.35)'
  },
  // 主色调
  primaryColor: '#cb4e49',
  // 是否启用面包屑导航
  enableBreadcrumb: false,
  COS: {
    SecretId: process.env.VUE_APP_COS_SecretId || '未从环境变量中加载到SecretId',
    SecretKey: process.env.VUE_APP_COS_SecretKey || '未从环境变量中加载到SecretId',
    Bucket: process.env.VUE_APP_COS_Bucket || 'scrm-file-1258960627',
    Region: process.env.VUE_APP_COS_Region || 'ap-beijing'
  },
  dictionaries: {
    '性别': 'gender',
    '启用状态': 'status',
    '是否部门负责': 'is_leader',
    '组织类型': 'organization_Type',
    '待办类型': 'todo_Type',
    '注册渠道': 'register_Channel',
    '产品类型': 'product_type',
    '意向等级': 'intention_grade',
    '商机介绍': 'introduce_flag',
    '售前客户介绍标识': 'befcus_introduce_flags',
    '售前客户意向等级': 'befcus_intention_grade',
    '售前客户跟进状态': 'befcus_follow_status_flags',
    '售前客户跟进客户状态': 'befcus_status_flags',
    '门店活码类型': 'store_livecode_type',
    '内容类型': 'content_type',
    '触达类型': 'content_reach',
    '接入点类型': 'cf_point_type'
  },
  contentType: {
    'content_type_001': '文案',
    'content_type_002': '图片',
    'content_type_003': '图文',
    // 'content_type_004': '小程序',
    'content_type_005': '视频',
    'content_type_006': '文件'
    // 'content_type_007': '视频号'
  },
  reachType: {
    'content_reach_001': '企业群发',
    'content_reach_002': '个人群发',
    'content_reach_003': '内容发送',
    'content_reach_004': '朋友圈',
    'operate_guide_001': '沟通策略'
  },
  sopType: {
    1: '客户运营',
    2: '内容运营',
    3: '朋友圈',
    4: '活动运营'
  },
  operateType: {
    'content_reach': '内容触达',
    'operate_guide': '运营指导'
  }
}

