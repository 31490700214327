const route = [{
  path: '/groupTools/:tenant',
  name: 'groupTools',
  component: () => import('@/views/groupTools/tools'),
  meta: { routeName: '群工具', unGetUser: true }
}, {
  path: '/groupTemplates/:tenant',
  name: 'groupTemplates',
  component: () => import('@/views/groupTools/templates'),
  meta: { routeName: '群工具-模板列表', unGetUser: true }
}]
export default route
