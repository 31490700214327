/**
 * @Author:
 * @Date: 2019-06-20 10:00:00
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-08-10 13:20:05
 **/
import Drawer from './VcDrawer.vue'

export default {
  install(Vue) {
    Vue.component('Drawer', Drawer)
  }
}
