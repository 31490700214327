<template>
  <el-cascader
    ref="cascader"
    :value="value"
    :placeholder="placeholder"
    :options="list"
    :props="{ multiple, ...elseProps}"
    :collapse-tags="tags"
    :filterable="filterable"
    clearable
    @change="changeInput"
    >
  </el-cascader>
</template>
<script>
export default {
  props: {
    value: {
      type: [Array, String],
      default: () => { [] }
    },
    list: {
      type: Array,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: true
    },
    tags: {
      type: Boolean,
      default: true
    },
    elseProps: {
      type: Object,
      default: () => ({
        label: 'text',
        value: 'value',
        children: 'children'
      })
    },
    placeholder: {
      type: String,
      default: '搜索'
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    changeInput(val) {
      this.$emit('change', val)
    },
    getCheckedNodes() {
      return this.$refs.cascader.getCheckedNodes()
    },
    clearCheckedNodes() {
      this.$refs.cascader.clearCheckedNodes()
    }
  }
}
</script>
<style lang="less" scoped>

</style>
