/**
 * @Author:
 * @Date: 2019-06-20 10:00:00
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-08-09 18:02:24
 **/
import BaseDialog from './BaseDialog.vue'

export default {
  install(Vue) {
    Vue.component('BaseDialog', BaseDialog)
  }
}
