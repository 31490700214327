// 埋点处理
import Vue from 'vue'
import { vSearchReport } from '@/api/modules/vSearch'

/**
 * 埋点数据上报
 * @param params 上报数据对象
 */
Vue.prototype._track = function(params) {
  // 功能暂时屏蔽
  return new Promise((resolve, reject) => {
    vSearchReport(params).then(
      res => {
        if (res.code === 0) {
          // store.commit('userAuth/usersCurrentInfo', res.data)
          console.log(params, '埋点数据上报成功')
          resolve(res)
        }
      },
      err => {
        window.console.log(err, 'usersCurrentInfo-err')
        reject(err)
      }
    )
  })
}
