import ClipboardJS from 'clipboard'
import { MessageBox, Message } from 'element-ui'
import COS from 'cos-js-sdk-v5'
/**
 * @summary 打电话
 * @param {string|number} phoneNumber - 电话号码
 */
export const callPhone = phoneNumber => {
  const value = phoneNumber.toString().replace(/[^\d]+/g, '')
  const call = document.createElement('a')
  call.href = 'tel:' + value
  document.body.appendChild(call)
  const evt = document.createEvent('MouseEvents')
  evt.initEvent('click', false, false)
  call.dispatchEvent(evt)
  document.body.removeChild(call)
}

/**
 * @summary 发短信
 * @param {string|number} phoneNumber - 电话号码
 * @param {string} message - 短信模板
 */
export const sendSms = (phoneNumber, message) => {
  const value = phoneNumber.toString().replace(/[^\d]+/g, '')
  const call = document.createElement('a')
  if (window.navigator.userAgent.includes('Android')) {
    call.href = `sms:${value}?body=${message}`
  }
  else if (window.navigator.userAgent.includes('Mac OS')) {
    call.href = `sms:${value}&body=${message}`
  }
  else {
    return window.navigator.userAgent
  }
  console.log(call)
  console.log(call.href)
  document.body.appendChild(call)
  const evt = document.createEvent('MouseEvents')
  evt.initEvent('click', false, false)
  call.dispatchEvent(evt)
  document.body.removeChild(call)
}

/**
 * @summary 复制
 * @param {string|HTMLElement|HTMLElement[]} btn - 需要传入一个 DOM选择器, HTML 元素, 或者 HTML元素列表.
 * @param {string|number} content - 复制内容
 * @param {function} callback - 回调函数,错误优先（err, msg）
 */
export const copy = (btn = '.btn', content, callback) => {
  return new Promise((resolve, reject) => {
    try {
      const clipboard = new ClipboardJS(btn, {
        text: function() {
          return content
        }
      })
      console.log(clipboard)
      clipboard.on('success', function(e) {
        e.clearSelection()
        resolve({
          code: 'success',
          ...e
        })
        if (typeof callback === 'function') {
          callback({
            code: 'success',
            ...e
          })
        }
        clipboard.destroy()
      })
      clipboard.on('error', function(e) {
        reject({
          code: 'success',
          ...e
        })
        if (typeof callback === 'function') {
          callback({
            code: 'success',
            ...e
          })
        }
        clipboard.destroy()
      })
    }
    catch (err) {
      reject(err)
    }
  })
}

/**
 * @summary postMessage 发送消息
 * @param {string} keyword - 关键字：描述发送消息作用
 * @param {object|string|number} data - 消息
 * @param {string} aimOrigin - 验证目标地址
 */
export const postMessageSendInfo = (
  keyword,
  data,
  aimOrigin = window.location.origin
) => {
  // 参数组装
  const message = { keyword, data }
  // 判断环境
  const iframe = document.getElementsByTagName('iframe')[0]
  if (iframe) {
    // 父环境
    iframe.contentWindow.postMessage(JSON.stringify(message), aimOrigin)
  }
  else {
    // 子环境
    parent.postMessage(JSON.stringify(message), aimOrigin)
  }
}

/**
 * @summary postMessage 接收消息
 * @param {function|null} cb - 回调函数：返回关键字keyword和数据data
 * @param {object|null} exchange - 回礼：{ keyword, data }
 * @param {string} aimOrigin - 验证目标地址
 */
export const postMessageReceiveInfo = (
  cb = null,
  exchange = null,
  aimOrigin = window.location.origin
) => {
  window.addEventListener(
    'message',
    event => {
      if (event.origin !== aimOrigin) return
      if (event.data) {
        try {
          const { keyword, data } = JSON.parse(event.data)
          if (typeof cb === 'function') cb(keyword, data)
          if (typeof exchange === 'object') {
            event.source.postMessage(JSON.stringify(exchange), event.origin)
          }
        }
        catch (err) {
          console.log(err)
        }
      }
    },
    false
  )
}

/**
 * @summary 打开上传图片
 * @param {object} options - 配置参数
 * count	    number	        1	                          否	最多可以选择的图片张数
 * sizeType	  Array.<string>	['original', 'compressed']	否	所选的图片的尺寸
 * sourceType	Array.<string>	['album', 'camera']	        否	选择图片的来源
 * @returns {array} iOS: 文件 localId 列表 Android: 文件路径列表
 */
export const chooseImage = (options = {}) => {
  const baseOptions = {
    count: 1
  }
  Object.assign(baseOptions, options)
  return new Promise((resolve, reject) => {
    wx.chooseImage({
      ...baseOptions,
      success: res => {
        resolve(res.localIds)
      },
      fail: err => {
        reject(err)
      }
    })
  })
}

/**
 * @summary 获取本地图片接口
 * @param {string} localId - 图片的localID
 * @param {string} option - localData 不一定会添加 base64 头
 * @returns {Promise<string>} 返回文件 base64 信息
 */
export const getLocalImgData = (localId, option = '') => {
  return new Promise(resolve => {
    wx.getLocalImgData({
      localId, // 图片的 localID
      success: res => {
        resolve(option + res.localData)
      }
    })
  })
}

/**
 * @summary 将base64转换为blob
 * @param {string} base64Data - 文件 base64 信息
 * @returns {Blob} blob
 */
export const dataURLtoBlob = base64Data => {
  const arr = base64Data.split(',')
  const mime = arr[0].match(/:(.*?)/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

/**
 * @summary 将blob转换为file
 * @param {*} theBlob - blob
 * @param {string} fileName - 文件名
 * @returns {File} 文件
 */
export const blobToFile = (theBlob, fileName, base64Data) => {
  theBlob.lastModifiedDate = new Date()
  theBlob.name = fileName
  theBlob.base64Data = base64Data
  return theBlob
}

/**
 * @summary base64 转文件
 * @param {string} base64Data - 文件 base64 信息
 * @param {string} imgName - 生成文件名
 * @returns {File} 文件
 */
export const base64ToFile = (base64Data, imgName) => {
  // console.log('base64ToFile', base64Data, imgName)
  return Promise.resolve(
    blobToFile(dataURLtoBlob(base64Data), imgName, base64Data)
  )
}

/**
 * @summary 封装获取本地文件
 * @param {Object} options - 配置信息
 * baseImageName 基础文件名
 * 其它配置参考 chooseImage
 * @returns {Q.Promise<*>} 文件列表
 */
export const getLocalImageFile = options => {
  const { baseImageName = Date.now(), ...config } = options
  console.log(baseImageName, window.navigator.userAgent)
  return chooseImage(config)
    .then(localIds =>
      window.navigator.userAgent.includes('Android')
        ? Promise.all(localIds.map(localId => getLocalImageBase64(localId)))
        : Promise.all(localIds.map(localId => getLocalImgData(localId)))
    )
    .then(base64List =>
      Promise.all(
        base64List.map((base64, index) =>
          base64ToFile(base64, `${baseImageName}-${index}.jpg`)
        )
      )
    )
}

/**
 * @summary 获取本地图片 base64 信息
 * @param {string} localPath - 本地图片地址
 * @returns {Promise<unknown>}
 */
export const getLocalImageBase64 = localPath => {
  console.log(localPath)
  return new Promise(resolve => {
    const image = new Image()
    const timer = setInterval(() => {
      console.log(
        image,
        image.width,
        image.height,
        image.src,
        image.crossOrigin
      )
      if (image.width && image.height) {
        console.log('getLocalImageBase64-success')
        clearInterval(timer)
        resolve(getCanvasBase64(image))
      }
    }, 500)
    image.crossOrigin = 'Anonymous'
    image.src = localPath
    image.style.position = 'absolute'
    image.style.zIndex = '9999'
    image.style.top = '0'
    // image.style.opacity = '0'
    document.body.appendChild(image)
    console.log('getLocalImageBase64-image', image)
  })
}

/**
 * @summary 通过 canvas 获取图片 base64 信息
 * @param img - img DOM
 * @returns {string}
 */
export const getCanvasBase64 = img => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = img.width
  canvas.height = img.height

  ctx.drawImage(img, 0, 0)
  const ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
  return canvas.toDataURL('image/' + ext)
}

/**
 * @summary 获取 url 中的 uri 与 query 请求参数
 * @param {string} url - 请求地址 url
 * @return {object} - { uri, params }
 */
export const getUrlParams = url => {
  const regList = url.match(/[^(?|&)]*/g).filter(item => item)

  const uri = regList.shift()

  const params = regList.reduce((pre, cur) => {
    const [key, value] = cur.split('=')
    if (pre[key]) {
      if (Array.isArray(pre[key])) {
        pre[key].push(value)
      }
      else {
        pre[key] = [pre[key], value]
      }
    }
    else {
      pre[key] = value
    }
    return pre
  }, {})

  return { uri, params }
}

/**
 * @summary 拼接 uri 与 query 请求参数
 * @param {string} uri - 资源路径
 * @param {object} params - query 请求入参
 * @return {string} - url
 */
export const recombineUrlParams = (uri, params = {}) => {
  const { uri: Uri, params: Params } = getUrlParams(uri)
  const url = Object.entries({ ...Params, ...params }).reduce(
    (pre, [key, val]) => {
      if (Array.isArray(val)) {
        pre += `${key}=${val.join(`&${key}=`)}&`
      }
      else {
        pre += `${key}=${val}&`
      }
      return pre
    },
    Uri + '?'
  )
  return url.slice(0, -1)
}

/**
 * @summary 时间格式转化 -> YYYYMMDD hh:mm:ss
 * @param {string|number|object} value - 时间
 * @param {Boolean} YDMFlag - 是否 YYYYMMDD
 * @return {string} - YYYYMMDD hh:mm:ss 或者 YYYYMMDD
 */
export const dateFilter = (value, YDMFlag = false) => {
  if (value) {
    let d = null
    if (typeof value === 'string') {
      d =
        value.length === 13
          ? new Date(parseInt(value))
          : new Date(value.replace(/-/g, '/'))
    }
    else if (typeof value === 'number') {
      d = new Date(value)
    }
    else {
      d = value
    }
    if (d) {
      const date = {
        Y: d.getFullYear(),
        M: d.getMonth() > 8 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1),
        D: d.getDate() > 9 ? d.getDate() : '0' + d.getDate(),
        h: d.getHours() > 9 ? d.getHours() : '0' + d.getHours(),
        m: d.getMinutes() > 9 ? d.getMinutes() : '0' + d.getMinutes(),
        s: d.getSeconds() > 9 ? d.getSeconds() : '0' + d.getSeconds()
      }
      return (
        `${date.Y}-${date.M}-${date.D}` +
        (YDMFlag ? '' : ` ${date.h}:${date.m}:${date.s}`)
      )
    }
    return ''
  }
  return ''
}

/* eventType is 'touchstart', 'touchmove', 'touchend'... */
export const sendTouchEvent = (clientX, clientY, element, eventType) => {
  const touchObj = new Touch({
    identifier: Date.now(),
    target: element,
    clientX,
    clientY,
    radiusX: 2.5,
    radiusY: 2.5,
    rotationAngle: 10,
    force: 0.5
  })

  const touchEvent = new TouchEvent(eventType, {
    cancelable: true,
    bubbles: true,
    touches: [touchObj],
    targetTouches: [],
    changedTouches: [touchObj],
    shiftKey: true
  })

  element.dispatchEvent(touchEvent)
}

// 乘法
export const accMul = (arg1 = 0, arg2 = 0) => {
  let m = 0
  const s1 = arg1.toString()
  const s2 = arg2.toString()
  try {
    m += s1.split('.')[1].length
  }
  catch (e) {
    console.log('e', e)
  }
  try {
    m += s2.split('.')[1].length
  }
  catch (e) {
    console.log('e', e)
  }
  return (
    (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
    Math.pow(10, m)
  )
}
// BD-09 to GCJ-02
export const bd_decrypt = (bdLat, bdLon) => {
  return new Promise(resolve => {
    if (bdLat && bdLon) {
      const x_pi = (3.14159265358979324 * 3000.0) / 180.0
      const x = bdLon - 0.0065
      const y = bdLat - 0.006
      const z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi)
      const theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi)
      const gcjLon = z * Math.cos(theta)
      const gcjLat = z * Math.sin(theta)
      if (gcjLon && gcjLat) {
        resolve({ lat: gcjLat, lon: gcjLon })
      }
    }
  })
  // return {'lat' : gcjLat, 'lon' : gcjLon}
}

export const searchObj = search => {
  return JSON.parse(
    '{"'.concat(
      decodeURIComponent(search.substring(1))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"'),
      '"}'
    )
  )
}

// (4)   节流函数
/** leading-false，trailing-true：默认情况，即在延时结束后才会调用函数
leading-true，trailing-true：在延时开始时就调用，延时结束后也会调用
leading-true, trailing-false：只在延时开始时调用
 * 节流，多次触发，间隔时间段执行
 * @param {Function} func
 * @param {Int} wait
 * @param {Object} options
 */
export function throttle(func, wait = 500, options) {
  // container.onmousemove = throttle(getUserAction, 1000);
  var timeout, context, args
  var previous = 0
  if (!options) options = { leading: false, trailing: true }

  var later = function() {
    previous = options.leading === false ? 0 : new Date().getTime()
    timeout = null
    func.apply(context, args)
    if (!timeout) context = args = null
  }

  var throttled = function() {
    var now = new Date().getTime()
    if (!previous && options.leading === false) previous = now
    var remaining = wait - (now - previous)
    context = this
    args = arguments
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      func.apply(context, args)
      if (!timeout) context = args = null
    }
    else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining)
    }
  }
  return throttled
}

/**
 *(5)
 * @param {*} func 要进行debouce的函数
 * @param {*} wait 等待时间,默认500ms
 * @param {*} immediate 是否立即执行
 */
export function debounce(func, wait = 500, immediate = false) {
  var timeout
  return function() {
    var context = this
    var args = arguments

    if (timeout) clearTimeout(timeout)
    if (immediate) {
      // 如果已经执行过，不再执行
      var callNow = !timeout
      timeout = setTimeout(function() {
        timeout = null
      }, wait)
      if (callNow) func.apply(context, args)
    }
    else {
      timeout = setTimeout(function() {
        func.apply(context, args)
      }, wait)
    }
  }
}

//  格式化时间函数
//  formatDate(date, "yyyy-MM-dd  hh:mm:ss");
export function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }
  const o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + ''
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      )
    }
  }
  return fmt
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

/**
 *(5)
 * @param {*} data 去重的数组
 * @param {*} name 要去重的json对应的key值
 */
export const filterByName = (data, name = null) => {
  var dest = []
  for (var i = 0; i < data.length; i++) {
    var ai = data[i]
    if (i === 0) {
      dest.push(ai)
    }
    else {
      var filterData = dest.filter(function(e) {
        if (name) {
          return e[name] === ai[name]
        }
        else {
          return e === ai
        }
      })

      if (filterData.length === 0) {
        dest.push(ai)
      }
    }
  }
  return dest
}

// 验证phone还是pc
export const vaildatePc = function() {
  const userAgentInfo = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ]
  let flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.includes(Agents[v])) {
      flag = false
      console.log('phone')
      break
    }
  }
  return flag
}

//  验证是不是苹果设备
export const isAppleDevice = () =>
  /Mac|iPod|iPhone|iPad/.test(navigator.platform)

// 判断数据类型
export const dataType = (value, type) => {
  return (Object.prototype.toString.call(value)).toLocaleLowerCase() === `[object ${type}]`
}

export const downloadImage = (imgsrc, name) => { // 下载图片地址和图片名
  var image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = function() {
    var canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    var context = canvas.getContext('2d')
    context.drawImage(image, 0, 0, image.width, image.height)
    var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

    var a = document.createElement('a') // 生成一个a元素
    var event = new MouseEvent('click') // 创建一个单击事件
    a.download = name || 'photo' // 设置图片名称
    a.href = url // 将生成的URL设置为a.href属性
    a.dispatchEvent(event) // 触发a的单击事件
  }
  image.src = imgsrc
}

// 是否是移动端
export const isMobileClient = () => (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))

// 获取浏览器窗口的尺寸
export const getWindowSize = () => {
  return window.innerWidth || document.body.clientWidth || document.documentElement.clientWidth
}

export const getWindowHeight = () => {
  return window.innerHeight || document.body.clientHeight || document.documentElement.clientHeight
}

export const debounce2 = (fn, delay) => {
  return function(...args) {
    fn.timer && clearTimeout(fn.timer)
    fn.timer = setTimeout(() => fn.apply(this, args), delay)
  }
}

// 等待
export const sleep = async timeLen => {
  return new Promise(resolve => setTimeout(resolve, timeLen))
}

// 需要确认的操作提示
export const confirmAction = async(msg = '确认进行此操作?', type = 'warning') => {
  return MessageBox.confirm(msg, '提示信息', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type,
    dangerouslyUseHTMLString: true
  })
}
export const lowerCase = (str) => {
  return str.toLowerCase()
}
export const windowDownload = (url) => {
  if (window) {
    const userAgent = lowerCase(navigator.userAgent)
    if (userAgent.includes('wxwork') && userAgent.includes('windows')) {
      // 创建a链接
      var a = document.createElement('a')
      a.href = url
      a.download = '' // 文件名称，不填默认为当前请求名称
      // 触发a链接点击事件，浏览器开始下载文件
      a.click()
    }
    else {
      window.location.href = url
    }
  }
}
// Message 消息提示
export const messageAction = async(msg = '暂无...', type = 'info') => {
  // if (store.state.app.isNotifyMark) return
  // store.dispatch('app/createNotifyState', true)
  Message({
    message: msg,
    showClose: true,
    type,
    duration: 3500
  })
  await sleep(3500)
  // store.dispatch('app/createNotifyState', false)
}
// 生成 uuid key
export const createUidKey = (key = '') => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
  return key + uuid
}
// 构造cos对象
export const cosObj = (resToken) => {
  return new COS({
    getAuthorization: (options, callback) => {
      callback({
        TmpSecretId: resToken.secretId,
        TmpSecretKey: resToken.secretKey,
        XCosSecurityToken: resToken.token,
        ExpiredTime: resToken.expireTime
      })
    }
  })
}
