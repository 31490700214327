import { ciKey } from '@/api/modules/cosApi/index'
export default {
  namespaced: true,
  state: {
    cosKey: null
  },
  mutations: {
    SET_COSKEY(state, params) {
      state.cosKey = params
    }
  },
  actions: {
    async getCiKey({ dispatch, commit, state }, { dir, isCore = 0 }) {
      if (!state.cosKey || isCore) {
        await dispatch('ciKeyApi', dir)
      }
    },
    ciKeyApi({ commit, state }, dir = '/') {
      ciKey(dir).then(res => {
        if (res.code === 0) {
          // Object.assign(this.cosKey, res.data);
          commit('SET_COSKEY', res.data)
        }
      })
    }
  },
  getters: {
    cosKey: state => state.cosKey
  }
}
