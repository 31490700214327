const env = process.env.VUE_APP_ENV || 'test'
export const ENV_MAP = {
  test: {
    120047: { // key为租户id
      corpId: 'ww6202f0e1f3f16195', // 企微应用在此配置企业id
      appId: '1000003' // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
    }
  },
  uat: {
    1001: { // key为租户id
      corpId: 'ww8a53bac7a8929400', // 企微应用在此配置企业id
      appId: '1000061'// 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
    }
  },
  prod: {
    1001: { // key为租户id
      corpId: 'ww8a53bac7a8929400', // 企微应用在此配置企业id
      appId: '1000061'// 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
    }
  }
}

JSCONFIG['scrm'] = JSCONFIG['scrm'] || {}
global.APP_CONFIG = {
  auth_type: 'webAuthLogin', // webAuthLogin:网页授权登录接入企微， scanCodeAuthLogin:扫码授权登录接入企微， customAuthLogin:自定义登录
  ...global.APP_CONFIG,
  ...ENV_MAP[env][global.APP_CONFIG.tenantId],
  ...JSCONFIG['scrm'][global.APP_CONFIG.tenantId] // ConfigMap中的配置key为”activityDetail“，可以将相关配置放在ConfigMap
}
