import service from '@/api'
import { del } from '@/api'
// mta上报
import mta from 'mta-h5'
import store from '@/store/index'

const pre = '/dapi/b-access-layer/v1/access'
let sessionId = String(new Date().getTime() + Math.random())
let querySource = ''
/**
 * 知识库搜索
 */
export const knowledgeSearch = params => {
  querySource = params.querySource || ''
  if (params.pageIndex === 1) {
    sessionId = String(new Date().getTime() + Math.random())
  }
  params.sessionId = sessionId
  if (process.env.VUE_APP_PROJ === 'audi') {
    // 区分是否是素材管理员
    if (params.materialType === '1') {
      return service.post(
        '/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search',
        params,
        {
          headers: { queryActionFlag: true }
        }
      )
    }
    // /knowledge-template-center/v1/client/material/rank/list
    else {
      return service.post(
        '/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search?materialFlag=true',
        params,
        {
          headers: { queryActionFlag: true }
        }
      )
    }
  }
  return service.post(
    `${pre}/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search`,
    params,
    {
      headers: { queryActionFlag: true }
    }
  )
}
/**
 *  推荐模块，搜索接口 图片和视频
 *  奥迪专用
 */
export const recommendSearchImageAndVideo = params => {
  return service.post(
    '/knowledge-template-center/v1/client/material/rank/list?materialFlag=true',
    params
  )
}
/**
 *  推荐模块，搜索接口 文章
 *  奥迪专用
 */
// knowledge-content-management/v1/content/rank/list
export const recommendSearchArticle = params => {
  return service.post(
    '/knowledge-content-management/v1/content/rank/list',
    params
  )
}
/**
 *  推荐模块，搜索接口 话术
 *  奥迪专用
 */
export const recommendSearchChart = params => {
  return service.post(
    '/knowledge-chatstratage-management/v1/client/chat/rank/list',
    params
  )
}
/**
 * 查询知识图谱
 */
export const getAtlas = params => {
  if (process.env.VUE_APP_PROJ === 'audi' && process.env.VUE_APP_ENV === 'test') {
    return service.post(
      '/knowle-graph-engine/v1/kgqa/query',
      params
    )
  }
  if (process.env.VUE_APP_PROJ === 'audi' && process.env.VUE_APP_ENV === 'uat') {
    return service.post(
      '/knowledge-graph-engine/v1/kgqa/query',
      params
    )
  }
  return service.post(
    `${pre}/knowledge-graph-engine/v1/kgqa/query`,
    params
  )
}
/**
 * 查询车系
 */
export const getAtlasSeries = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-graph-engine/v1/graph/mata/entity/query',
      params
    )
  }
  return service.post(
    `${pre}/knowledge-graph-engine/v1/graph/mata/entity/query`,
    params
  )
}

/**
 * C端查询个人话术列表
 */
export const personalSpeechArtList = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-chatstratage-management/v1/client/chat/list/personal',
      params
    )
  }
  return service.post(
    `${pre}/knowledge-chatstratage-management/v1/client/chat/list/personal`,
    params
  )
}
/**
 * 话术详情
 */
export const speechArtDetail = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.get('/knowledge-chatstratage-management/v1/client/chat/get', {
      params
    })
  }
  return service.get(
    `${pre}/knowledge-chatstratage-management/v1/client/chat/get`,
    {
      params
    }
  )
}
/**
 * 话术详情关联素材列表
 */
export const speechArtRelationList = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.get(
      '/knowledge-chatstratage-management/v1/client/chat/getByChat',
      {
        params
      }
    )
  }
  return service.get(
    `${pre}/knowledge-chatstratage-management/v1/client/chat/getByChat`,
    {
      params
    }
  )
}
// export const speechArtRelationList = params => {
//   return service.post(`${pre2}/v1/client/chat/getRelation/${params.id}`, params)
// }
/**
 * 素材详情--图文详情
 */
export const articleMaterialDetail = params => {
  return service.get(`${pre}/content-management/content/get2C`, { params })
}
/**
 * 素材详情--图片 ，视频 ，文件
 */
export const materialDetail = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-template-center/v1/client/material/approvalDetail',
      params
    )
  }
  return service.get(`${pre}/template-center/material/detail`, { params })
}
/**
 * 素材详情--相关推荐
 */
export const linkList = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-template-center/v1/client/material/link/list',
      params
    )
  }
  return service.post(
    `${pre}/template-center/material/getRelation/${params.id}`,
    params
  )
}
/**
 * 新增话术
 */
export const speechArtAdd = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-chatstratage-management/v1/client/chat/add/personal',
      params
    )
  }
  return service.post(
    `${pre}/knowledge-chatstratage-management/v1/client/chat/add/personal`,
    params
  )
}
/**
 * 修改话术
 */
export const speechArtUpdate = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-chatstratage-management/v1/client/chat/update/personal',
      params
    )
  }
  return service.post(
    `${pre}/knowledge-chatstratage-management/v1/client/chat/update/personal`,
    params
  )
}
/**
 * 多级分组查询
 */
export const groupList = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post('/knowledge-config-center/v1/group/allRootList', params)
  }
  return service.post(`${pre}/knowledge-config-center/v1/group/list`, params)
}
/**
 * 多级分组查询(无分组)
 */
export const groupList2 = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post('/knowledge-config-center/v1/group/allRootList', params)
  }
  return service.post(`${pre}/knowledge-config-center/v1/group/list2`, params)
}

/**
 * 数据上报
 */
export const vSearchReport = params => {
  const userInfo = store.getters['userAuth/getUsersCurrentInfo'] || {}
  params = {
    clickType: params.clickType, // 1页面访问，2列表页转发，3详情页转发（话术编辑后转发、海报制作后转发),4搜索操作，5搜索页点开素材详情
    query: params.query, // 搜索接口参数对象
    clickId: params.clickId, // 转发素材id
    clickTitle: params.clickTitle, // 转发素材标题
    contentType: params.contentType, // 1话术，2图文，3图片，4视频，5文件，6海报
    fields: params.fields, // 当前搜索过滤字段
    offset: params.offset, // 转发成功内容在总数据中第几条

    scene: self !== top ? 1 : 2, // 当前访问端，1web，2企微
    url: location.href.split('?')[0], // 当前页面地址
    redirectFrom: '', // 页面访问来源
    contentSource: 'knowledge', // 暂时写死knowledge
    link: '', // 传播链,记录用户id
    searchLimit: '10', // 当前搜索结果限制数量
    matchType: 'FUZZY', // 当前搜索匹配类型,ACCURATE精准搜索，FUZZY模糊匹配
    sortType: 'SIMILARITY', // 当前搜索排序类型 DEFAULT默认排序,SIMILARITY相似度
    searchTimeStart: new Date().getTime(), // 用做页面访问时间点/转发时间点/搜索起始时间点
    searchTimeEnd: '', // 用做搜索结束时间点
    sessionId,
    querySource,
    userOrgId: userInfo.org && userInfo.org.length ? userInfo.org[0] : '', // 转发者所属部门----需要主部门ID
    userOrgName: userInfo.orgName && userInfo.orgName.length ? userInfo.orgName[0] : '',
    userId: userInfo.id || '',
    userName: userInfo.userName || '', // 当前用户名称
    tenantId: global.APP_CONFIG.tenantId
  }
  // mta上报
  mta.up({
    appId: 'A11143237935',
    ts: parseInt(params.searchTimeStart / 1000), // 10位数秒级别时间戳
    plat: 'h5',
    path: params.url, // 页面路径
    ac: String(params.clickType), // 埋点行为标识
    dac: params.clickId, // 统计点击文章的ID
    ext1: JSON.stringify(params) // 用户自定义扩展1
  })
  // dac  统计文章ID
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post('/vsearch-data-report/v1/vsearch/report/search-statistic', params, {
      headers: { noSuccToast: true }
    })
  }
  return service.post(`${pre}/vsearch-data-report/v1/vsearch/report/search-statistic`, params, {
    headers: { noSuccToast: true }
  })
}

/**
 * 根据ids获取话术,话术暂不支持多选转发
 */
export const getChatMulSelByIds = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-chatstratage-management/v1/client/chat/list/ids',
      params,
      {
        headers: { noSuccToast: true }
      }
    )
  }
  return service.post(
    `${pre}/knowledge-chatstratage-management/v1/client/chat/list/ids`,
    params,
    {
      headers: { noSuccToast: true }
    }
  )
}
/**
 * 根据ids获取图文
 */
export const getContentMulSelByIds = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-content-management/v1/content/listByIds',
      params,
      {
        headers: { noSuccToast: true }
      }
    )
  }
  return service.post(`${pre}/content-management/content/listByIds`, params, {
    headers: { noSuccToast: true }
  })
}
/**
 * 根据ids获取素材（图片，海报，视频，文件）
 */
export const getMatMulSelByIds = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/knowledge-template-center/v1/client/material/listByIds',
      params,
      {
        headers: { noSuccToast: true }
      }
    )
  }
  return service.post(`${pre}/template-center/material/listByIds`, params, {
    headers: { noSuccToast: true }
  })
}
/**
 * 搜索历史
 */
export const searchHistory = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.get(
      '/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search-history/list',
      { params }
    )
  }
  return service.get(
    `${pre}/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search-history/list`,
    { params }
  )
}
/**
 * 清空搜索历史
 */
export const clearHistory = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.get(
      '/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search-history/clear-history',
      { params }
    )
  }
  return service.get(
    `${pre}/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search-history/clear-history`,
    { params }
  )
}
/**
 * 删除单个搜索历史
 */
export const removeHistory = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return del(
      '/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search-history/word',
      { ...params }
    )
  }
  return del(
    `${pre}/vsearch-search-proxy/v1/knowledge/vsearch-proxy/search-history/word`,
    { ...params }
  )
}
/**
 * 搜索联想词
 */
export const searchSuggest = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/vsearch-search-proxy/v1/knowledge/vsearch-proxy/suggest',
      params
    )
  }
  return service.post(
    `${pre}/vsearch-search-proxy/v1/knowledge/vsearch-proxy/suggest`,
    params
  )
}
/**
 * 意见反馈
 */
export const addFeedBack = params => {
  params.sessionId = sessionId
  params.tenantId = global.APP_CONFIG.tenantId
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.post(
      '/vsearch-data-report/v1/vsearch/feedback/add',
      params
    )
  }
  return service.post(
    `${pre}/vsearch-data-report/v1/vsearch/feedback/add`,
    params
  )
}
/**
 * 意见反馈字典
 */
export const feedBackList = params => {
  if (process.env.VUE_APP_PROJ === 'audi') {
    return service.get(
      '/vsearch-data-report/v1/vsearch/feedback/list-dict',
      params
    )
  }
  return service.get(
    `${pre}/vsearch-data-report/v1/vsearch/feedback/list-dict`,
    { params }
  )
}
