/**
 * @Author:
 * @Date: 2019-06-20 10:00:00
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-08-09 16:57:16
 **/
import FilterTable from './filterTable.vue'

export default {
  install(Vue) {
    Vue.component('FilterTable', FilterTable)
  }
}
