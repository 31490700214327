import Vue from 'vue'
import Vuex from 'vuex'
import userAuth from './modules/userAuth.js'

Vue.use(Vuex)

const config = {
  state: {},
  mutations: {},
  modules: {
    userAuth
  }
}

/**
 * 自动集合./modules下模块配置
 */
const setModule = function() {
  const requireModule = require.context('./modules', true, /\.js$/)
  requireModule.keys().forEach((filePath) => {
  // filePath为文件路径名
    const moduleConfig = requireModule(filePath)
    // 去掉路径和文件后缀只保留真正的文件名
    const fileName = filePath.replace(/^\.\//, '').replace(/\.\w+$/, '')
    // 动态添加模块
    if (moduleConfig.default) {
      config.modules[fileName] = moduleConfig.default
    }
  })
}

setModule()

const store = new Vuex.Store(config)

export default store
