<script>
import _ from 'lodash'
import moment from 'moment'
import configJs from '@/config/index'
import pinyin, { STYLE_FIRST_LETTER } from '@/components/Pinyin/index'
import Cascader from '@/components/FormPanel/Cascader.vue'
import ECascader from '@/components/FormPanel/ECascader.vue'
import SelectPage from '@/components/FormPanel/SelectPage.vue'
import SelectPageImage from '@/components/FormPanel/SelectPageImage.vue'
import Quarter from './Quarter.vue'
import BaseDialog from '@/components/BaseDialog/BaseDialog.vue'
import DatePicker from './DatePicker.vue'
import { on } from 'element-ui/lib/utils/dom'
import { throttle, sleep } from '@/utils/utils'
export default {
  name: 'TopFilter',
  directives: {
    selectLoadmore: {
      bind(el, binding, vnode) {
        if (binding.value) {
          const cb = throttle(binding.value, 500)
          on(vnode.componentInstance.$refs.scrollbar.$refs.wrap, 'scroll', function(ev) {
            const { clientHeight, scrollTop, scrollHeight } = ev.target
            // console.log(`clientHeight:${clientHeight} scrollTop: ${scrollTop}  scrollHeight:${scrollHeight}`)
            if (clientHeight + scrollTop + 150 >= scrollHeight) {
              cb()
            }
          })
        }
      }
    }
  },
  props: {
    btnText: {
      type: String,
      default: () => ''
    },
    list: {
      type: Array,
      required: true
    },
    rows: {
      type: Number,
      default: 1
    },
    cols: {
      type: Number,
      default: configJs.screenSize === 'small' ? 3 : 4
    },
    labelWidth: {
      type: [Number, String],
      default: 90
    },
    disabled: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: true
    },
    isSubmitBtn: {
      type: Boolean,
      default: true
    },
    isCustomQuery: {
      type: Boolean,
      default: false
    },
    isExportBtn: {
      type: Boolean,
      default: false
    },
    exportBtnText: {
      type: String,
      default: ''
    },
    exportBtnDisabled: {
      type: Boolean,
      default: false
    },
    clueSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.treeProps = { children: 'children', label: 'text' }
    this.prevForm = null
    this.arrayTypes = ['RANGE_DATE', 'RANGE_DATES', 'RANGE_INPUT', 'RANGE_INPUT_NUMBER', 'MULTIPLE_SELECT', 'MULTIPLE_CHECKBOX']
    return {
      form: {},
      expand: false, // 展开收起状态
      loading: false,
      visible: {},
      isShow: !1,
      CustomTagList: [], // 自定义标签
      curTagIndex: 0
    }
  },
  computed: {
    fieldNames() {
      return this.list
        .filter(x => !x.hidden)
        .map(x => x.fieldName)
        .filter(x => !!x)
    },
    formItemList() {
      const res = []
      this.list
        .filter(x => !x.hidden && x.fieldName)
        .forEach(x => {
          if (_.isObject(x.labelOptions) && x.labelOptions.fieldName) {
            res.push(x.labelOptions)
          }
          res.push(x)
        })
      return res
    },
    rules() {
      const target = {}
      this.list
        .filter(x => !x.hidden)
        .forEach(x => {
          if (!(x.fieldName && x.rules)) return
          target[x.fieldName] = x.rules
        })
      return target
    },
    isCollapse() {
      const total = this.list.filter(x => !x.hidden).length
      return this.collapse && total >= this.cols
    }
  },
  watch: {
    formItemList: {
      handler(nextProps, prevProps) {
        if (nextProps.length !== prevProps.length) {
          this.initialHandle()
        }
        this.debounce(this.resetFormData, 10)(nextProps)
      },
      deep: true
    },
    form: {
      handler(nextProps) {
        const res = this.difference(nextProps, this.prevForm)
        if (!Object.keys(res).length) return
        for (const key in res) {
          const target = this.formItemList.find(x => x.fieldName === key)
          if (!target) continue
          // 同步 initialValue 的值
          target.initialValue = res[key]
        }
        this.prevForm = { ...nextProps }
      },
      deep: true
    },
    fieldNames(nextProps, prevProps) {
      if (!_.isEqual(nextProps, prevProps)) {
        this.initialHandle()
      }
      this.$nextTick(() => this.doClearValidate(this.$refs.form))
    },
    expand(val) {
      if (!this.isCollapse) return
      this.$emit('onCollapse', val)
    }
  },
  created() {
    this.initialHandle()
  },
  methods: {
    initialHandle() {
      this.form = this.createFormData()
      this.prevForm = { ...this.form }
    },
    getInitialValue(item) {
      let { initialValue } = item
      const { type = '', fieldName } = item
      if (this.arrayTypes.includes(type)) {
        initialValue = initialValue || []
      }
      // 树选择器
      if (type === 'INPUT_TREE' && _.isUndefined(this[`${fieldName}TreeFilterTexts`])) {
        this[`${fieldName}TreeFilterTexts`] = ''
      }
      // 级联选择器
      if (type === 'INPUT_CASCADER' && _.isUndefined(this[`${fieldName}CascaderTexts`])) {
        this[`${fieldName}CascaderTexts`] = ''
      }
      return initialValue
    },
    createFormData() {
      const target = {}
      this.formItemList.forEach(x => {
        const val = this.getInitialValue(x)
        // 设置 initialValue 为响应式数据
        this.$set(x, 'initialValue', val)
        // 初始值
        target[x.fieldName] = val
      })
      return target
    },
    resetFormData(list) {
      list.forEach(x => {
        if (_.isEqual(x.initialValue, this.form[x.fieldName])) return
        this.form[x.fieldName] = this.getInitialValue(x)
        // 对组件外 js 动态赋值的表单元素进行校验
        this.doFormItemValidate(x.fieldName)
      })
    },
    createFormItemLabel(option) {
      const { form } = this
      const { label, type = 'SELECT', fieldName, itemList, options = {}, style = {}, disabled, change = () => {} } = option
      const { trueValue = '1', falseValue = '0', text = 'text', value = 'value' } = options
      return (
        <div class='label-wrap' style={{ ...style }}>
          {type === 'SELECT' && (
            <el-select v-model={form[fieldName]} filterable placeholder={''} disabled={disabled} onChange={change}>
              {itemList.map(x => (
                <el-option key={x.value} label={x[text]} value={x[value]} />
              ))}
            </el-select>
          )}
          {type === 'CHECKBOX' && (
            <span style='vertical-align: middle'>
              <span class='desc-text' style={{ paddingRight: '10px' }}>
                {label}
              </span>
              <el-checkbox v-model={form[fieldName]} trueLabel={trueValue} falseLabel={falseValue} disabled={disabled} onChange={change} />
            </span>
          )}
        </div>
      )
    },
    createFormItemDesc(option) {
      if (!option) return null
      const { isTooltip, style = {}, content = '描述信息...' } = option
      if (isTooltip) {
        return (
          <el-tooltip effect='dark' content={content} placement='right'>
            <i class='desc-icon el-icon-info'></i>
          </el-tooltip>
        )
      }
      return (
        <span class='desc-text' style={{ paddingLeft: '10px', ...style }}>
          {content}
        </span>
      )
    },
    RENDER_FORM_ITEM(option) {
      let { label } = option
      const { fieldName, labelWidth, labelOptions, style = {}, render = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <div class='desc-text' style={{ ...style }}>
            {render()}
          </div>
        </el-form-item>
      )
    },
    INPUT(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, descOptions, style = {}, placeholder = '请输入...', unitRender, minlength = 0, maxlength = 999, autofocus, readonly, disabled, change = () => {}, onInput = () => {}, onFocus = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-input
            v-model={form[fieldName]}
            placeholder={placeholder}
            minlength={minlength}
            maxlength={maxlength}
            readonly={readonly}
            autofocus={autofocus}
            disabled={disabled}
            style={{ ...style }}
            clearable
            onChange={val => {
              form[fieldName] = val.trim()
              change(form[fieldName])
            }}
            onInput={onInput}
            onFocus={onFocus}
            nativeOnKeydown={this.enterEventHandle}
          >
            {unitRender && <template slot='append'>{<div style={disabled && { pointerEvents: 'none' }}>{unitRender()}</div>}</template>}
          </el-input>
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    INPUT_NUMBER(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, descOptions, style = {}, placeholder = '请输入...', disabled, isControls = !0, min = 0, max = 999999999999, maxlength, step = 1, precision, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-input-number
            v-model={form[fieldName]}
            placeholder={placeholder}
            disabled={disabled}
            style={{ ...style }}
            controls={isControls}
            controls-position='right'
            min={min}
            max={max}
            step={step}
            precision={precision}
            controls={false}
            clearable
            onChange={val => {
              if (maxlength > 0 && typeof val !== 'undefined') {
                const res = Number.parseInt(val).toString()
                if (res.length > maxlength) {
                  form[fieldName] = Number(res.slice(0, maxlength))
                }
              }
              change(form[fieldName])
            }}
            nativeOnKeydown={this.enterEventHandle}
          />
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    RANGE_INPUT(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, readonly, disabled, change = () => {} } = option
      const [startFieldName, endFieldName] = fieldName.split('|')
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-input
            v-model={form[fieldName][0]}
            readonly={readonly}
            disabled={disabled}
            style={{ width: `calc(50% - 7px)` }}
            clearable
            onChange={() => change({ [startFieldName]: form[fieldName][0] })}
          />
          <span style='display: inline-block; text-align: center; width: 14px;line-height: 30px'>-</span>
          <el-input
            v-model={form[fieldName][1]}
            readonly={readonly}
            disabled={disabled}
            style={{ width: `calc(50% - 7px)` }}
            clearable
            onChange={() => change({ [endFieldName]: form[fieldName][1] })}
          />
        </el-form-item>
      )
    },
    RANGE_INPUT_NUMBER(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, min = 0, max = 999999999999, step = 1, precision, readonly, disabled, change = () => {} } = option
      const [startVal = min, endVal = max] = form[fieldName]
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-input-number
            v-model={form[fieldName][0]}
            controls-position='right'
            min={min}
            max={endVal}
            step={step}
            precision={precision}
            readonly={readonly}
            disabled={disabled}
            controls={false}
            style={{ width: `calc(50% - 7px)` }}
            clearable
            onChange={() => change(form[fieldName])}
          />
          <span style='display: inline-block; text-align: center; width: 14px;line-height: 30px'>-</span>
          <el-input-number
            v-model={form[fieldName][1]}
            controls-position='right'
            min={startVal}
            max={max}
            step={step}
            precision={precision}
            readonly={readonly}
            disabled={disabled}
            controls={false}
            style={{ width: `calc(50% - 7px)` }}
            clearable
            onChange={() => change(form[fieldName])}
          />
        </el-form-item>
      )
    },
    INPUT_TREE(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, itemList, style = {}, placeholder = '请输入...', readonly, disabled, defaultExpandAll, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-popover
            v-model={this.visible[fieldName]}
            popper-class='input-tree'
            transition='el-zoom-in-top'
            placement='bottom-start'
            trigger='click'
            on-after-leave={() => {
              this[`${fieldName}TreeFilterTexts`] = ''
              this.treeFilterTextHandle(fieldName)
            }}
          >
            <div class='el-input--small input-tree-wrap' style={{ maxHeight: '250px', overflowY: 'auto', ...style }}>
              <input
                value={this[`${fieldName}TreeFilterTexts`]}
                class='el-input__inner'
                placeholder='树节点过滤'
                onInput={ev => {
                  this[`${fieldName}TreeFilterTexts`] = ev.target.value
                  this.treeFilterTextHandle(fieldName)
                }}
              />
              <el-tree
                ref={`tree-${fieldName}`}
                style={{ marginTop: '4px' }}
                data={itemList}
                props={this.treeProps}
                defaultExpandAll={!defaultExpandAll}
                expandOnClickNode={false}
                filterNodeMethod={this.filterNodeHandle}
                on-node-click={data => {
                  this.treeNodeClickHandle(fieldName, data)
                  change(this.form[fieldName])
                }}
              />
            </div>
            <el-input
              slot='reference'
              value={this.createInputTreeValue(fieldName, itemList)}
              placeholder={placeholder}
              readonly={readonly}
              disabled={disabled}
              clearable
              style={disabled && { pointerEvents: 'none' }}
              onClear={() => {
                this.treeNodeClickHandle(fieldName, {})
                change(form[fieldName])
              }}
              nativeOnKeydown={this.enterEventHandle}
            />
          </el-popover>
        </el-form-item>
      )
    },
    CASCADER(option) {
      const { form } = this
      const { label, fieldName, labelWidth, itemList = [], options = {}, change = () => {} } = option
      const { multiple = false, elseProps = { label: 'text', value: 'value', children: 'children' }} = options
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          <ECascader
            style='width:100%'
            ref='ecascader'
            list={itemList}
            value={form[fieldName]}
            multiple={multiple}
            elseProps={elseProps}
            onChange={val => {
              form[fieldName] = val
              this[`${fieldName}CascaderVal`] = this.$refs.ecascader.getCheckedNodes()
              change(form[fieldName], this[`${fieldName}CascaderVal`])
            }}
          />
        </el-form-item>
      )
    },
    INPUT_CASCADER(option) {
      const { form } = this
      let { label } = option
      const { titles = [], fieldName, labelWidth, labelOptions, itemList = [], options = {}, style = {}, placeholder = '请选择...', readonly, disabled, change = () => {} } = options
      console.log(option, 1111)
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-popover v-model={this.visible[fieldName]} transition='el-zoom-in-top' placement='bottom-start' trigger='click'>
            <div style={{ maxHeight: '250px', overflowY: 'auto', ...style }}>
              <Cascader
                value={form[fieldName]}
                onInput={val => {
                  this.cascaderChangeHandle(fieldName, val)
                }}
                list={itemList}
                labels={titles}
                style={style}
                onChange={data => {
                  change(form[fieldName], this[`${fieldName}CascaderTexts`])
                }}
                onClose={() => (this.visible[fieldName] = false)}
              />
            </div>
            <el-input
              slot='reference'
              value={this[`${fieldName}CascaderTexts`]}
              placeholder={placeholder}
              readonly={readonly}
              disabled={disabled}
              clearable
              style={disabled && { pointerEvents: 'none' }}
              onClear={() => {
                this.cascaderChangeHandle(fieldName, [])
                change(form[fieldName], this[`${fieldName}CascaderTexts`])
              }}
            />
          </el-popover>
        </el-form-item>
      )
    },
    SEARCH_HELPER(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, request = {}, style = {}, placeholder = '请输入...', disabled, change = () => {}, select = () => {}, clearable } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-autocomplete
            v-model={form[fieldName]}
            placeholder={placeholder}
            disabled={disabled}
            style={{ ...style }}
            clearable={clearable}
            onChange={change}
            onSelect={select}
            nativeOnKeydown={this.enterEventHandle}
            fetchSuggestions={(queryString, cb) => this.querySearchAsync(request, fieldName, queryString, cb)}
          />
        </el-form-item>
      )
    },
    SEARCH_HELPER_WEB(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, style = {}, placeholder = '请输入...', disabled, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-autocomplete
            v-model={form[fieldName]}
            valueKey='text'
            placeholder={placeholder}
            disabled={disabled}
            style={{ ...style }}
            clearable
            onChange={change}
            nativeOnKeydown={this.enterEventHandle}
            fetchSuggestions={(queryString, cb) => this.querySearchHandle(fieldName, queryString, cb)}
            scopedSlots={{
              default: props => {
                const { item } = props
                return <span>{item.text}</span>
              }
            }}
          />
        </el-form-item>
      )
    },
    SELECT(option) {
      return this.createSelectHandle(option)
    },
    MULTIPLE_SELECT(option) {
      return this.createSelectHandle(option, true)
    },
    SELECT_PAGES(option) {
      const { form } = this
      const { label, fieldName, labelWidth, options = {}} = option
      const { multiple = false, elseProps = { label: 'text', value: 'value' }, fetchApi = () => {}, itemNode = false } = options
      return (
        <el-form-item key={fieldName} label={label + '：'} labelWidth={labelWidth} prop={fieldName}>
          <SelectPage fetchApi={fetchApi} v-model={form[fieldName]} multiple={multiple} options={elseProps} itemNode={itemNode}/>
        </el-form-item>
      )
    },
    SELECT_PAGES_IMAGE(option) {
      const { form } = this
      const { label, fieldName, labelWidth, options = {}} = option
      const { multiple = false, elseProps = { label: 'text', value: 'value' }, fetchApi = () => {}, itemNode = false } = options
      return (
        <el-form-item key={fieldName} label={label + '：'} labelWidth={labelWidth} prop={fieldName}>
          <SelectPageImage fetchApi={fetchApi} v-model={form[fieldName]} multiple={multiple} options={elseProps} itemNode={itemNode}/>
        </el-form-item>
      )
    },
    RANGE_DATES(option) {
      const { label, fieldName, labelWidth, style = {}, disabled, change = () => {}, minDate = '', maxDate = '' } = option
      const { form } = this
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          <DatePicker
            disabled={disabled}
            style={{ ...style }}
            value={form[fieldName]}
            minDate={minDate}
            maxDate={maxDate}
            onChange={date => {
              form[fieldName] = date || []
              change(date)
            }}
          />
        </el-form-item>
      )
    },
    DATE(option) {
      const { form } = this
      const conf = {
        date: {
          placeholder: '选择日期',
          valueFormat: 'yyyy-MM-dd HH:mm:ss'
        },
        datetime: {
          placeholder: '选择时间',
          valueFormat: 'yyyy-MM-dd HH:mm:ss'
        },
        exactdate: {
          placeholder: '选择日期',
          valueFormat: 'yyyy-MM-dd'
        },
        week: {
          placeholder: '选择周',
          valueFormat: 'yyyy 年 MM 月 第 WW 周'
        },
        quarter: {
          placeholder: '选择季度',
          valueFormat: 'yyyy 年 QQ 季度'
        },
        month: {
          placeholder: '选择月份',
          valueFormat: 'yyyy-MM'
        },
        year: {
          placeholder: '选择年份',
          valueFormat: 'yyyy'
        }
      }
      let { label } = option
      const { fieldName, labelWidth, labelOptions, dateType = 'date', minDateTime, maxDateTime, style = {}, disabled, change = () => {}, clearable } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      if (dateType === 'quarter') {
        let computedDisabled = []
        if (typeof disabled === 'boolean') {
          computedDisabled = [disabled, disabled, disabled, disabled]
        }
        // eslint-disable-next-line valid-typeof
        else if (typeof disabled === 'array') {
          computedDisabled = disabled
        }
        return (
          <Quarter
            ref={fieldName + 'Quarter'}
            key={fieldName}
            label={label}
            labelWidth={labelWidth}
            prop={fieldName}
            format={conf[dateType].valueFormat}
            disabled={computedDisabled}
            defaultValue={form[fieldName]}
            getValue={(str, arr) => {
              form[fieldName] = str
              change(arr, form[fieldName])
            }}
          />
        )
      }
      else if (dateType === 'week') {
        return (
          <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
            {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
            <el-date-picker
              type={dateType.replace('exact', '')}
              v-model={form[fieldName]}
              format={conf[dateType].valueFormat}
              placeholder={conf[dateType].placeholder}
              disabled={disabled}
              clearable={clearable}
              style={{ ...style }}
              onChange={m => {
                form[fieldName] = m
                change(
                  [
                    moment(m)
                      .startOf('week')
                      .format('YYYY-MM-DD HH:mm:ss'),
                    moment(m)
                      .endOf('week')
                      .format('YYYY-MM-DD HH:mm:ss')
                  ],
                  form[fieldName]
                )
              }}
            />
          </el-form-item>
        )
      }
      let currentVal
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-date-picker
            ref={fieldName}
            type={dateType.replace('exact', '')}
            value={this.formatDate(form[fieldName], conf[dateType].valueFormat)}
            onInput={val => {
              val = val === null ? undefined : val
              form[fieldName] = val
            }}
            value-format={conf[dateType].valueFormat}
            placeholder={conf[dateType].placeholder}
            disabled={disabled}
            clearable={clearable}
            style={{ ...style }}
            picker-options={{
              disabledDate: time => {
                return this.setDisabledDate(time, [minDateTime, maxDateTime])
              }
            }}
            nativeOnInput={ev => {
              const val = ev.target.value.replace(/(\d{4})-?(\d{2})-?(\d{2})/, '$1-$2-$3')
              currentVal = val
              ev.target.value = val
            }}
            onBlur={val => {
              if (!currentVal) return
              form[fieldName] = this.dateToText(currentVal)
              change(form[fieldName])
              currentVal = undefined
            }}
            nativeOnKeydown={ev => {
              if (ev.keyCode === 13) {
                form[fieldName] = this.dateToText(ev.target.value)
                this.$refs[fieldName].hidePicker()
              }
            }}
            onChange={() => change(form[fieldName])}
          />
        </el-form-item>
      )
    },
    // DATE_TIME -> 为了兼容旧版控件类型参数
    DATE_TIME(option) {
      return this.DATE({ ...option, dateType: 'datetime' })
    },
    RANGE_DATE(option) {
      const { form } = this
      const conf = {
        daterange: {
          placeholder: ['开始日期', '结束日期'],
          valueFormat: 'yyyy-MM-dd HH:mm:ss'
        },
        datetimerange: {
          placeholder: ['开始时间', '结束时间'],
          valueFormat: 'yyyy-MM-dd HH:mm:ss'
        },
        exactdaterange: {
          placeholder: ['开始日期', '结束日期'],
          valueFormat: 'yyyy-MM-dd'
        },
        exactweekrange: {
          placeholder: ['开始日期', '结束日期'],
          valueFormat: 'yyyy-MM-dd',
          format: 'yyyy第WW周'
        },
        monthrange: {
          placeholder: ['开始月份', '结束月份'],
          valueFormat: 'yyyy-MM'
        }
      }
      let { label } = option
      const { fieldName, labelWidth, labelOptions, dateType = 'daterange', minDateTime, maxDateTime, style = {}, disabled, change = () => {}, clearable, popperClass, shortCutRange = [0] } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      const [startDate = minDateTime, endDate = maxDateTime] = form[fieldName]
      // 日期区间快捷键方法
      const createPicker = (picker, unit, num) => {
        let end = moment()
        let start = moment(end).subtract(num, unit)
        if (picker.selectionMode === 'week') {
          end = end.startOf('week')
          start = start.add(1, 'week') // 此选择模式（周）下，当前周计算在内
          start = start.startOf('week')
        }
        if (picker.selectionMode === 'month') {
          end = end.startOf('month')
          start = start.add(1, 'month') // 此选择模式（月）下， 当前月份计算在内
          start = start.startOf('month')
        }
        end = end.toDate()
        start = start.toDate()
        // start.setTime(start.getTime() - 3600 * 1000 * 24 * Number(days));
        form[fieldName] = this.formatDate([start, end], conf[dateType].valueFormat)
        picker.$emit('pick', start)
      }
      const pickers = [
        {
          text: '最近一周',
          onClick(picker) {
            createPicker(picker, 'week', 1)
          }
        },
        {
          text: '最近一个月',
          onClick(picker) {
            createPicker(picker, 'month', 1)
          }
        },
        {
          text: '最近三个月',
          onClick(picker) {
            createPicker(picker, 'month', 3)
          }
        },
        {
          text: '最近六个月',
          onClick(picker) {
            createPicker(picker, 'month', 6)
          }
        }
      ]
      const cls = [`range-date`, { [`disabled`]: disabled }]
      let startVal
      let endVal
      const isWeekRange = dateType === 'exactweekrange'
      const shortcuts = dateType.includes('date') || isWeekRange ? (shortCutRange.length === 1 ? pickers : pickers.slice(shortCutRange[0], shortCutRange[1])) : pickers.slice(1)
      return (
        <el-form-item key={fieldName} ref={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <div class={cls} style={{ ...style }}>
            <el-date-picker
              ref={`${fieldName}-start`}
              type={dateType.replace('exact', '').slice(0, -5)}
              value={this.formatDate(form[fieldName][0], conf[dateType].valueFormat)}
              onInput={val => {
                val = val === null ? undefined : val
                form[fieldName] = [val, form[fieldName][1]]
              }}
              pickerOptions={{
                disabledDate: time => {
                  return this.setDisabledDate(time, [minDateTime, endDate])
                },
                shortcuts: shortcuts.length ? shortcuts : null
              }}
              value-format={conf[dateType].valueFormat}
              format={conf[dateType].format || conf[dateType].valueFormat}
              style={{ width: `calc(50% - 13px)` }}
              placeholder={conf[dateType].placeholder[0]}
              disabled={disabled}
              clearable={clearable}
              prefix-icon='el-icon-date'
              popper-class={popperClass}
              nativeOnInput={ev => {
                const val = ev.target.value.replace(/(\d{4})-?(\d{2})-?(\d{2})/, '$1-$2-$3')
                startVal = val
                ev.target.value = val
              }}
              onBlur={val => {
                if (!startVal) return
                form[fieldName] = [this.dateToText(startVal), form[fieldName][1]]
                startVal = undefined
              }}
              nativeOnKeydown={ev => {
                if (ev.keyCode === 13) {
                  form[fieldName] = [this.dateToText(ev.target.value), form[fieldName][1]]
                  this.$refs[`${fieldName}-start`].hidePicker()
                }
              }}
              onChange={() => change(form[fieldName])}
            />
            <span class={disabled ? 'is-disabled' : ''} style='display: inline-block; line-height: 30px; text-left: center; width: 10px;'>
              -
            </span>
            <el-date-picker
              ref={`${fieldName}-end`}
              type={dateType.replace('exact', '').slice(0, -5)}
              value={this.formatDate(form[fieldName][1], conf[dateType].valueFormat)}
              onInput={val => {
                val = val === null ? undefined : val
                form[fieldName] = [form[fieldName][0], val]
              }}
              pickerOptions={{
                disabledDate: time => {
                  return this.setDisabledDate(time, [startDate, maxDateTime])
                }
              }}
              value-format={conf[dateType].valueFormat}
              format={conf[dateType].format || conf[dateType].valueFormat}
              style={{ width: `calc(50% + 3px)` }}
              placeholder={conf[dateType].placeholder[1]}
              disabled={disabled}
              clearable={clearable}
              prefix-icon='el-icon-date'
              popper-class={popperClass}
              nativeOnInput={ev => {
                const val = ev.target.value.replace(/(\d{4})-?(\d{2})-?(\d{2})/, '$1-$2-$3')
                endVal = val
                ev.target.value = val
              }}
              onBlur={val => {
                if (!endVal) return
                form[fieldName] = [form[fieldName][0], this.dateToText(endVal)]
                endVal = undefined
              }}
              nativeOnKeydown={ev => {
                if (ev.keyCode === 13) {
                  form[fieldName] = [form[fieldName][0], this.dateToText(ev.target.value)]
                  this.$refs[`${fieldName}-end`].hidePicker()
                }
              }}
              onChange={() => change(form[fieldName])}
            />
          </div>
        </el-form-item>
      )
    },
    CHECKBOX(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, descOptions, options = {}, style = {}, disabled, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      const { trueValue = '1', falseValue = '0' } = options
      form[fieldName] !== trueValue && (form[fieldName] = falseValue)
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-checkbox v-model={form[fieldName]} disabled={disabled} style={{ ...style }} trueLabel={trueValue} falseLabel={falseValue} onChange={change} />
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    MULTIPLE_CHECKBOX(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, descOptions, itemList, limit, style = {}, disabled, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-checkbox-group v-model={form[fieldName]} max={limit} style={{ ...style }} onChange={change}>
            {itemList.map(x => {
              return (
                <el-checkbox key={x.value} label={x.value} disabled={disabled}>
                  {x.text}
                </el-checkbox>
              )
            })}
          </el-checkbox-group>
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    RADIO(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, descOptions, itemList, style = {}, disabled, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-radio-group v-model={form[fieldName]} style={{ ...style }} onChange={change}>
            {itemList.map(x => (
              <el-radio key={x.value} label={x.value} disabled={disabled}>
                {x.text}
              </el-radio>
            ))}
          </el-radio-group>
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    RADIO_BUTTON(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, descOptions, itemList, style = {}, disabled, change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-radio-group v-model={form[fieldName]} style={{ ...style }} onChange={change}>
            {itemList.map(x => (
              <el-radio-button key={x.value} label={x.value} disabled={disabled}>
                {x.text}
              </el-radio-button>
            ))}
          </el-radio-group>
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    TEXT_AREA(option) {
      const { form } = this
      let { label } = option
      const { fieldName, labelWidth, labelOptions, style = {}, placeholder = '请输入...', disabled, rows = 2, maxlength = 200 } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-input
            type='textarea'
            v-model={form[fieldName]}
            placeholder={placeholder}
            disabled={disabled}
            style={{ ...style }}
            clearable
            autosize={{ minRows: rows }}
            maxlength={maxlength}
            showWordLimit
          />
        </el-form-item>
      )
    },
    createSelectHandle(option, multiple = false) {
      const { form } = this
      let { label } = option
      const { fieldName,
        labelWidth,
        labelOptions,
        descOptions,
        filterable,
        request = {},
        style = {},
        placeholder = '请选择...',
        disabled,
        limit = 0,
        clearable = !0,
        popperAppendToBody = false,
        _className = '',
        lazy = 0,
        change = () => {} } = option
      label.length > 0 && !label.match(/:|：/) && (label += '：')
      !!label.match(/:/) && (label = label.replace(/:/, '：'))
      const { fetchApi, params = {}} = request
      let { itemList } = option
      const { options = {}} = option
      const { text = 'text', value = 'value' } = options
      if (!option.itemList && fetchApi) {
        itemList = this[`${fieldName}ItemList`] || []
        if (!_.isEqual(this[`${fieldName}PrevParams`], params)) {
          this[`${fieldName}PrevParams`] = params
          this.querySelectOptions(request, fieldName)
        }
      }
      if (typeof this[`${fieldName}lazyCount`] === 'undefined') {
        this[`${fieldName}lazyCount`] = lazy ? 1 : 0
      }
      const loadMore = (...ev) => {
        this.$nextTick(() => {
          // console.log(this[`${fieldName}OriginItemList`], itemList, lazy * this[`${fieldName}lazyCount`])
          const originListFlag = !this[`${fieldName}OriginItemList`] || (this[`${fieldName}OriginItemList`] && this[`${fieldName}OriginItemList`].length > lazy * this[`${fieldName}lazyCount`])
          if (lazy && originListFlag) {
            // console.log(this[`${fieldName}lazyCount`])
            this[`${fieldName}lazyCount`]++
            this.$forceUpdate()
          }
        })
      }
      return (
        <el-form-item key={fieldName} label={label} labelWidth={labelWidth} prop={fieldName}>
          {labelOptions && <span slot='label'>{this.createFormItemLabel(labelOptions)}</span>}
          <el-select
            class={_className}
            multiple={multiple}
            v-select-loadmore={lazy ? loadMore : () => {}}
            multipleLimit={limit}
            collapseTags={multiple}
            filterable={filterable}
            value={form[fieldName]}
            onInput={val => {
              if (!(multiple && filterable)) {
                form[fieldName] = val
              }
              else {
                form[fieldName] = val
                // setTimeout(() => (form[fieldName] = val), 20);
              }
            }}
            placeholder={placeholder}
            disabled={disabled}
            style={{ ...style }}
            clearable={clearable}
            nativeOnKeydown={this.enterEventHandle}
            popper-append-to-body={popperAppendToBody}
            on-visible-change={visible => {
              if (filterable && !visible) {
                if (lazy) {
                  this[`${fieldName}lazyCount`] = 1
                }
                setTimeout(() => {
                  this.filterMethodHandle(fieldName)
                }, 300)
              }
            }}
            onChange={val => {
              const obj = itemList.find(x => x[value] === val) || {}
              const name = obj[text]
              change(val, !multiple ? name : undefined)
              if (!filterable) return
              this.filterMethodHandle(fieldName, '')
              if (lazy) {
                console.log(form[fieldName])
                // this.$forceUpdate()
              }
            }}
            // filterMethod={queryString => {
            //   if (!filterable) return;
            //   if(lazy) {
            //     this[`${fieldName}lazyCount`] = 1
            //   }
            //   this.filterMethodHandle(fieldName, queryString);
            // }}
            onBlur={val => {
              console.log(this[`${fieldName}lazyCount`], 777)
              if (!filterable) return
              // setTimeout(()=>{  // bugfix： 筛选模式下，第一次选择不生效的bug
              this.filterMethodHandle(fieldName, '')
              // }, 300)
            }}
          >
            {itemList
              .filter((x, ind) => {
                const selected = Array.isArray(form[fieldName]) ? form[fieldName] : [form[fieldName]]
                return lazy ? this[`${fieldName}lazyCount`] * lazy > ind || selected.includes(x[value]) : true
              })
              .map(x => (
                <el-option key={x[value]} label={x[text]} value={x[value]} disabled={x.disabled} title={x._title} />
              ))}
          </el-select>
          {this.createFormItemDesc(descOptions)}
        </el-form-item>
      )
    },
    // 设置日期控件的禁用状态
    setDisabledDate(time, [minDateTime, maxDateTime]) {
      const min = minDateTime
        ? moment(minDateTime)
          .toDate()
          .getTime()
        : 0
      const max = maxDateTime
        ? moment(maxDateTime)
          .toDate()
          .getTime()
        : 0
      if (min && max) {
        return !(time.getTime() >= min && time.getTime() <= max)
      }
      if (min) {
        return time.getTime() < min
      }
      if (max) {
        return time.getTime() > max
      }
      return false
    },
    // 下拉框的筛选方法
    filterMethodHandle(fieldName, queryString = '') {
      const target = this.formItemList.find(x => x.fieldName === fieldName)
      const itemList = target.itemList || this[`${fieldName}ItemList`] || []
      if (!this[`${fieldName}OriginItemList`]) {
        this[`${fieldName}OriginItemList`] = itemList
      }
      const res = queryString ? this[`${fieldName}OriginItemList`].filter(this.createSearchHelpFilter(queryString)) : this[`${fieldName}OriginItemList`]
      if (!this[`${fieldName}ItemList`]) {
        target.itemList = res
      }
      else {
        this[`${fieldName}ItemList`] = res
        this.$forceUpdate()
      }
    },
    // 获取下拉框数据
    async querySelectOptions({ fetchApi, params = {}, datakey = '', valueKey = 'value', textKey = 'text' }, fieldName) {
      if (process.env.VUE_APP_MOCK_DATA === 'true') {
        const res = require('@/mock/sHelperData').default
        this[`${fieldName}ItemList`] = res.data.map(x => ({ value: x[valueKey], text: x[textKey] }))
      }
      else {
        const res = await fetchApi(params)
        if (res.resultCode === 200) {
          const dataList = !datakey ? res.data : _.get(res.data, datakey, [])
          this[`${fieldName}ItemList`] = dataList.map(x => ({ value: x[valueKey], text: x[textKey] }))
        }
      }
      this.$forceUpdate()
    },
    // 获取搜索帮助数据
    async querySearchAsync(request, fieldName, queryString = '', cb) {
      const { fetchApi, params = {}, datakey = '', valueKey } = request
      if (process.env.VUE_APP_MOCK_DATA === 'true') {
        const res = require('@/mock/sHelperData').default
        setTimeout(() => {
          cb(this.createSerachHelperList(res.data, valueKey))
        }, 500)
      }
      else {
        const res = await fetchApi({ ...{ [fieldName]: queryString }, ...params })
        if (res.code === '000000' || res.resultCode === 200) {
          const dataList = !datakey ? res.data : _.get(res.data, datakey, [])
          cb(this.createSerachHelperList(dataList, valueKey))
        }
      }
    },
    // 创建搜索帮助数据列表
    createSerachHelperList(list, valueKey) {
      return list.map(x => ({ value: x[valueKey] }))
    },
    querySearchHandle(fieldName, queryString = '', cb) {
      const { itemList = [] } = this.formItemList.find(x => x.fieldName === fieldName) || {}
      const res = queryString ? itemList.filter(this.createSearchHelpFilter(queryString)) : itemList
      cb(res)
    },
    createSearchHelpFilter(queryString) {
      return state => {
        const pyt = _.flatten(pinyin(state.text, { style: STYLE_FIRST_LETTER })).join('')
        const str = `${state.text}|${pyt}`
        return str.toLowerCase().includes(queryString.toLowerCase())
      }
    },
    // 创建树节点的值
    createInputTreeValue(fieldName, itemList) {
      const { text = '' } = this.deepFind(itemList, this.form[fieldName]) || {}
      return text
    },
    // 树控件顶部文本帅选方法
    treeFilterTextHandle(key) {
      this.$refs[`tree-${key}`].filter(this[`${key}TreeFilterTexts`])
    },
    // 树结构的筛选方法
    filterNodeHandle(value, data) {
      if (!value) return true
      return data.text.indexOf(value) !== -1
    },
    // 树节点单机事件
    treeNodeClickHandle(fieldName, { value, disabled }) {
      if (disabled) return
      this.form[fieldName] = value
      this.visible[fieldName] = false
    },
    // 级联选择器值变化处理方法
    cascaderChangeHandle(fieldName, data) {
      this.form[fieldName] = data.map(x => x.value).join(',') || undefined
      this[`${fieldName}CascaderTexts`] = data.map(x => x.text).join('/')
      // 强制重新渲染组件
      this.$forceUpdate()
    },
    createFormItem() {
      return this.list
        .filter(x => !x.hidden)
        .map(item => {
          const VNode = !this[item.type] ? null : item.render ? this.RENDER_FORM_ITEM(item) : this[item.type](item)
          VNode['type'] = item.type
          return VNode
        })
    },
    enterEventHandle(ev) {
      if (ev.keyCode !== 13) return
      setTimeout(() => this.submitForm(ev))
    },
    async loadingHandler() {
      this.loading = true
      await sleep(300)
      this.loading = false
    },
    isValidateValue(val) {
      return Array.isArray(val) ? val.length : !!val
    },
    // 表单数据是否通过非空校验
    isPassValidate(form) {
      for (const key in this.rules) {
        if (this.rules[key].some(x => x.required) && !this.isValidateValue(form[key])) {
          return false
        }
      }
      return true
    },
    doClearValidate($compRef) {
      $compRef && $compRef.clearValidate()
    },
    doFormItemValidate(fieldName) {
      this.$refs.form.validateField(fieldName)
    },
    excuteFormData(form) {
      this.formItemList
        .filter(x => ['RANGE_DATE', 'RANGE_INPUT_NUMBER'].includes(x.type))
        .map(x => x.fieldName)
        .forEach(fieldName => {
          if (form[fieldName].length > 0) {
            // 处理可能出现的风险 bug
            form[fieldName] = Object.assign([], [undefined, undefined], form[fieldName])
            if (form[fieldName].every(x => _.isUndefined(x))) {
              form[fieldName] = []
            }
            // if (form[fieldName].some(x => _.isUndefined(x))) {
            //   let val = form[fieldName].find(x => !_.isUndefined(x));
            //   form[fieldName] = [val, val];
            // }
          }
        })
      for (const attr in form) {
        if (attr.includes('|') && Array.isArray(form[attr])) {
          const [start, end] = attr.split('|')
          form[start] = form[attr][0]
          form[end] = form[attr][1]
        }
      }
    },
    submitForm(ev, type) {
      ev && ev.preventDefault()
      let isErr
      this.excuteFormData(this.form)
      if (Object.keys(this.rules).length === 0) return this.$emit('filterChange', this.form, type)

      this.$refs.form.validate(valid => {
        isErr = !valid
        if (valid) {
          this.loadingHandler()
          return this.$emit('filterChange', this.form, type)
        }
        // 校验没通过，展开
        this.expand = true
      })
      return isErr
    },
    resetForm() {
      let cloneForm = _.cloneDeep(this.form)
      // 重置表单项
      this.$refs.form.resetFields()
      this.formItemList.forEach(x => {
        if (x.noResetable) {
          this.form[x.fieldName] = cloneForm[x.fieldName]
        }
      })
      this.excuteFormData(this.form)
      for (const k in this.form) {
        this.$refs[k + 'Quarter'] && this.$refs[k + 'Quarter'].reset()
      }
      this.$emit('resetChange', this.form)
      this.isPassValidate(this.form) && this.$emit('filterChange', this.form, 'reset')
      // 清空变量，释放内存
      cloneForm = null
      // 解决日期区间(拆分后)重复校验的 bug
      this.$nextTick(() => {
        this.formItemList.forEach(x => {
          if (x.type === 'RANGE_DATE') {
            this.doClearValidate(this.$refs[x.fieldName])
          }
        })
      })
    },
    exportForm(ev) {
      if (!this.exportBtnDisabled) {
        this.submitForm(ev, 'export')
      }
    },
    toggleHandler() {
      this.expand = !this.expand
    },
    createButton(rows, total) {
      const { cols, expand, isCollapse, loading, disabled, btnText, isExportBtn, exportBtnText, exportBtnDisabled = false, clueSearch } = this
      const colSpan = 24 / cols
      // 默认收起
      let offset = rows * cols - total > 0 ? rows * cols - total - 1 : 0
      // 展开
      if (!isCollapse || expand) {
        offset = cols - (total % cols) - 1
      }
      return this.isSubmitBtn ? (
        <el-col key='-' class='top-filter-handle-btn-group' span={colSpan} offset={offset * colSpan} style={clueSearch ? 'marginTop : 20px; textAlign: right' : 'textAlign: right'}>
          {isCollapse ? (
            <el-button size='small' type='primary' style='padding: 9px;' disabled={disabled} onClick={this.toggleHandler}>
              <i class={expand ? 'el-icon-arrow-up' : 'el-icon-arrow-down'} />
            </el-button>
          ) : null}
          <el-button size='small' type='primary' loading={loading} disabled={disabled} onClick={this.submitForm}>
            {btnText || '查 询'}
          </el-button>
          <el-button size='small' disabled={disabled} onClick={this.resetForm}>
            重 置
          </el-button>
          {isExportBtn ? (
            <el-button size='small' type='primary' icon='el-icon-download' disabled={exportBtnDisabled} onClick={this.exportForm}>
              {exportBtnText || '导 出'}
            </el-button>
          ) : null}
        </el-col>
      ) : null
    },
    createCustomQuery() {
      if (!this.$props.isCustomQuery) return null
      const { CustomTagList, curTagIndex, isShow } = this
      const editScheme = CustomTagList[curTagIndex]
      return (
        <el-col key='S-Q' style={{ padding: '0 15px', textAlign: 'left' }}>
          {CustomTagList.map((x, i) => (!x.isAdd ? <el-button key={i}>{x.labelName}</el-button> : null))}
          <el-button
            size='small'
            type='primary'
            onClick={() => {
              this.isShow = !0
              this.getPlaceholderHeight()
            }}
          >
            自定义查询管理
          </el-button>
          <BaseDialog visible={isShow} width='800px' containerStyle={{ padding: 0 }} title='自定义查询管理' {...{ on: { 'update:visible': val => (this.isShow = val) }}}>
            <div class='SQ_container'>
              <div class='SQ_tit'>
                <div>查询方案</div>
                <div>查询条件</div>
              </div>
              <div class='SQ_box_l'>
                <ul>
                  {CustomTagList.map((x, i) => (
                    <li
                      key={i}
                      class={`SQB_item_l ${curTagIndex === i && 'SQ_selected'}`}
                      onClick={() => {
                        this.curTagIndex = i
                      }}
                    >
                      {x.handel === 'edit' ? (
                        <el-input
                          placeholder='请输入方案名称'
                          value={x.labelName}
                          {...{
                            on: {
                              input: val => {
                                CustomTagList[i].labelName = val
                                this.CustomTagList = JSON.parse(JSON.stringify(CustomTagList))
                              },
                              blur: () => {
                                CustomTagList[i].handel = ''
                                this.CustomTagList = JSON.parse(JSON.stringify(CustomTagList))
                              }
                            }
                          }}
                        ></el-input>
                      ) : (
                        <span>{x.labelName} </span>
                      )}
                      <span>
                        <i
                          class='el-icon-edit'
                          onClick={() => {
                            CustomTagList[i]['handel'] = 'edit'
                            this.CustomTagList = JSON.parse(JSON.stringify(CustomTagList))
                          }}
                        ></i>
                        <i class='el-icon-document-copy'></i>
                        <i class='el-icon-delete' slot='reference'></i>
                      </span>
                    </li>
                  ))}
                  <li>
                    <span
                      onClick={() => {
                        this.getCoutomAddList()
                        // editScheme.columns.forEach(x => x.value === e && (x.disabled = !0));
                      }}
                    >
                      <i class='el-icon-plus'></i>
                      <span>添加方案</span>
                    </span>
                  </li>
                </ul>
                <div ref='SQ_placeholder' class='placeholder'></div>
              </div>
              <div class='SQ_box_r'>
                <ul>
                  {editScheme &&
                    editScheme.selectCondition.map((x, i) => (
                      <li key={i}>
                        <el-select
                          value={x.fieldName}
                          placeholder='请选择'
                          style={'width:30%'}
                          {...{
                            on: {
                              change: e => {
                                const item = CustomTagList[0].selectCondition.filter(z => z.fieldName === e)[0]
                                // 第一列：列字段下拉框
                                editScheme.selectCondition[i].fieldName = e
                                // 第二列：条件符号下拉框
                                editScheme.selectCondition[i].condition = item.condition
                                // 第三列：组件数据下拉框
                                editScheme.selectCondition[i].selectItem = item.selectItem
                                editScheme.selectCondition[i].componentType = item.componentType
                                this.CustomTagList[curTagIndex] = editScheme
                              }
                            }
                          }}
                        >
                          {editScheme.columns.map((item, n) => (
                            <el-option key={item.value} label={item.label} value={item.value} disabled={item.disabled}></el-option>
                          ))}
                        </el-select>
                        <el-select
                          value={x.conditionCode}
                          placeholder='请选择'
                          style={'width: 18%;margin: 0 2%;'}
                          {...{ on: { change: e => (this.CustomTagList[curTagIndex].selectCondition[i].conditionCode = e) }}}
                        >
                          {editScheme.selectCondition[i].condition.map((item, n) => (
                            <el-option key={item.code} label={item.label} value={item.code}></el-option>
                          ))}
                        </el-select>
                        {x.componentType === 'input' && <el-input value={x.value[0]} style={'width: 48%'} />}
                        {x.componentType === 'select' && (
                          <el-select value={x.value[0]} placeholder='请选择' style={'width: 48%'} {...{ on: { change: e => (this.CustomTagList[curTagIndex].selectCondition[i].value = [e]) }}}>
                            {editScheme.selectCondition[i].selectItem.map((item, n) => (
                              <el-option key={item.code} label={item.label} value={item.code}></el-option>
                            ))}
                          </el-select>
                        )}
                      </li>
                    ))}

                  <li>
                    <span
                      onClick={() => {
                        if (this.CustomTagList[curTagIndex].selectCondition.length === this.CustomTagList[curTagIndex].columns.length) return
                        this.CustomTagList[curTagIndex].selectCondition.push({ value: [''], componentType: 'input', condition: [], selectItem: [], conditionCode: '', fieldName: '' })
                        this.getPlaceholderHeight()
                      }}
                    >
                      <i class='el-icon-plus'></i>
                      <span>添加查询项</span>
                    </span>
                  </li>
                </ul>
                <div style={{ textAlign: 'center', position: 'absolute', left: '30%', right: 0, bottom: '15px' }}>
                  <el-button size='small' type='primary'>
                    保存方案
                  </el-button>
                </div>
              </div>
            </div>
          </BaseDialog>
        </el-col>
      )
    },
    async getCoutomList() {
      if (!this.$props.isCustomQuery) return null
    },
    getPlaceholderHeight() {
      setTimeout(() => {
        this.$refs.SQ_placeholder.style.height =
          document.querySelector('.SQ_container').clientHeight - document.querySelector('.SQ_tit').clientHeight - document.querySelector('.SQ_box_l>ul').clientHeight + 'px'
      }, 0)
    },
    async getCoutomAddList() {
      // const { CustomTagList } = this
    },
    createFormLayout() {
      const unfixTypes = ['TEXT_AREA']
      const { cols, rows, expand, isCollapse } = this
      const colSpan = 24 / cols
      const formItems = this.createFormItem().filter(item => item !== null)
      const defaultPlayRows = rows > Math.ceil(formItems.length / cols) ? Math.ceil(formItems.length / cols) : rows
      const count = expand ? formItems.length : defaultPlayRows * cols - 1
      const colFormItems = formItems.map((Node, i) => {
        return (
          <el-col key={i} type={unfixTypes.includes(Node.type) ? 'UN_FIXED' : 'FIXED'} span={colSpan} style={{ display: !isCollapse || i < count ? 'block' : 'none' }}>
            {Node}
          </el-col>
        )
      })
      return [...colFormItems, this.createButton(defaultPlayRows, formItems.length), this.createCustomQuery(defaultPlayRows, formItems.length)]
    },
    dateToText(input = '') {
      const res = moment(input, 'YYYYMMDD').format('YYYY-MM-DD')
      return res !== 'Invalid date' ? res : ''
    },
    // 日期格式化
    formatDate(val, vf) {
      const arr = Array.isArray(val) ? val : [val]
      const res = arr.map(x => {
        return !x ? x : moment(x).format(vf.replace('yyyy', 'YYYY').replace('dd', 'DD'))
      })
      return Array.isArray(val) ? res : res[0]
    },
    // 函数防抖
    debounce(fn, delay) {
      return function(...args) {
        fn.timer && clearTimeout(fn.timer)
        fn.timer = setTimeout(() => fn.apply(this, args), delay)
      }
    },
    difference(newVal, oldVal) {
      const res = {}
      for (const key in newVal) {
        if (!_.isEqual(newVal[key], oldVal[key])) {
          res[key] = newVal[key]
        }
      }
      return res
    },
    deepFind(arr, mark) {
      let res = null
      for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i].children)) {
          res = this.deepFind(arr[i].children, mark)
        }
        if (res !== null) {
          return res
        }
        if (arr[i].value === mark) {
          res = arr[i]
          break
        }
      }
      return res
    },
    // 外部通过组件实例调用的方法
    SUBMIT_FORM() {
      const err = this.submitForm()
      return !err ? this.form : null
    },
    RESET_FORM() {
      this.resetForm()
    },
    async GET_FORM_DATA() {
      try {
        this.excuteFormData(this.form)
        await this.$refs.form.validate()
        return [false, this.form]
      }
      catch (err) {
        return [true, null]
      }
    }
  },
  render() {
    const { form, rules, labelWidth } = this
    return (
      <div class='top-filter'>
        <el-form ref='form' size='small' {...{ props: { model: form }}} rules={rules} label-width={`${labelWidth}px`} nativeOnSubmit={ev => ev.preventDefault()}>
          <el-row gutter={10}>{this.createFormLayout()}</el-row>
        </el-form>
      </div>
    )
  }
}
</script>

<style lang="less">
.top-filter {
  padding-top:10px;
  .el-col {
    min-height: 32px;
    margin-bottom: 12px;
    &[type='FIXED'] {
      height: 32px !important;
    }
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__label {
        height: 32px;
        // font-size: @textSizeSecondary;
        padding-right: 10px;
        .label-wrap {
          display: inline-block;
          max-width: calc(100% - 10px);
          .el-input__inner {
            border-color: #d9d9d9;
            padding: 0 8px;
            & + span.el-input__suffix {
              right: 0;
            }
          }
        }
      }
      .el-form-item__content {
        .el-form-item__error {
          margin-top: -2px;
          transform-origin: 0 50%;
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
      }
      .el-select {
        width: 100%;
      }
      .el-autocomplete {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .range-date {
        display: flex;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        transition: border-color 0.3s ease;
        &:hover:not(.disabled) {
          border-color: #9c9c9c;
        }
        .el-date-editor {
          input {
            border: 0 !important;
            height: 30px;
            padding-right: 0;
          }
          &:nth-of-type(1) {
            input {
              padding-left: 15px;
            }
            .el-input__suffix {
              right: -5px;
              .el-input__suffix-inner {
                background-color: #fff;
              }
            }
            .el-input__prefix {
              display: none;
            }
          }
          &:nth-of-type(2) {
            input {
              padding-left: 0px;
              padding-right: 30px;
            }
            .el-input__prefix {
              // display: none;
              position: absolute;
              right: 5px;
              left: inherit;
              display: inline-block;
            }
            .el-input__suffix {
              right: 0;
              .el-input__suffix-inner {
                background-color: #fff;
                padding-right: 5px;
              }
            }
          }
        }
        .is-disabled {
          background-color: #f2f2f2;
          color: rgba(0, 0, 0, 0.25);
          cursor: not-allowed;
        }
      }
      .el-textarea {
        display: block;
        .el-textarea__inner {
          font-family: inherit;
          overflow-y: auto;
        }
        .el-input__count {
          line-height: 1;
          right: 6px;
        }
      }
      .el-input-number {
        width: 100%;
        .el-input__inner {
          text-align: left !important;
        }
        .el-input-number__increase:hover ~ .el-input .el-input__inner:not(.is-disabled),
        .el-input-number__decrease:hover ~ .el-input .el-input__inner:not(.is-disabled) {
          border-color: #d9d9d9;
        }
      }
      .el-range-editor {
        padding-right: 5px;
        .el-range-separator {
          padding-left: 0;
          padding-right: 0;
        }
        .el-range__close-icon {
          width: 20px;
        }
      }
      .desc-icon {
        padding: 6px;
        font-size: 18px;
        vertical-align: middle;
      }
      .desc-text {
        font-size: 12px;
      }
      &.is-required .el-form-item__label {
        // color: #f5222d;
      }
      &.is-error {
        .range-date {
          // border-color: #f5222d;
        }
      }
    }
  }
}
.input-tree {
  .input-tree-wrap {
    padding-right: 10px;
    margin-right: -10px;
  }
  .el-tree {
    .el-tree-node[aria-disabled='true'] > .el-tree-node__content {
      color: rgba(0, 0, 0, 0.25);
      background: none;
      cursor: not-allowed;
      .is-leaf {
        pointer-events: none;
      }
    }
  }
}
.SQ_container {
  line-height: 36px;
  background-color: rgb(254, 249, 242);
  div {
    box-sizing: border-box;
  }
  .SQ_box_l,
  .SQ_box_r {
    display: inline-block;
    vertical-align: top;
    ul {
      li {
        &:last-of-type {
          color: #1890ff;
          cursor: pointer;
        }
      }
    }
  }
  .SQ_box_l {
    width: 30%;
    background-color: white;
    ul {
      .SQ_selected {
        border-right: 1px solid rgba(254, 249, 242, 1);
        background-color: rgb(254, 249, 242);
      }
      .SQB_item_l {
        > div {
          display: inline-block;
          width: 62%;
        }
        i {
          font-size: 16px;
          padding: 0 5px;
          cursor: pointer;
        }
      }
      li {
        border-right: 1px solid #ccc;
        padding: 0 10px 0 20px;
        &:not(:last-of-type) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .placeholder {
      background-color: white;
      border-right: 1px solid #ccc;
    }
  }
  .SQ_box_r {
    width: 70%;
    padding: 10px 20px 50px 20px;
  }
  .SQ_tit {
    font-weight: 600;
    background-color: #f1f8ff;
    > div {
      width: 30%;
      display: inline-block;
      padding: 0 20px;
      &:first-of-type {
        border-right: 1px solid #ccc;
      }
    }
  }
}
.top-filter-handle-btn-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
</style>
