const route = {
  path: '/speechbank/:tenant',
  name: 'speechbank',
  component: () => import(/* webpackChunkName: 'speechbank' */ '@/views/speechbank'),
  redirect: '/speechbank/:tenant/enter',
  meta: { routeName: '企业话术' },
  children: [
    {
      path: '/speechbank/:tenant/enter',
      name: 'speechbankenter',
      component: () => import(/* webpackChunkName: 'speechbankenter' */ '@/views/speechbank/enter'),
      meta: { routeName: '企业话术' }
    }
  ]
}
export default route

