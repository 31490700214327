/*
 * @Author: chenyc
 * @Date: 2021-09-09 18:25:49
 * @LastEditTime: 2021-09-10 10:09:43
 * @LastEditors: chenyc
 */
const gateway = '/dapi/api-gateway/h5/scrm-qwagg-service'
import { get } from '@/api/index'

export const ciKey = (dir) => get(gateway + `/api/v1/common/fileupload/secretKey?dir="${dir}"`, null, false) // cosKey
