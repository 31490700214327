// import { getAuthorizeInfo, getUsersCurrentInfo } from '@/api/modules/userAuth'
// import { urlEncode, getUrlParamsObj, delUrlParams } from '@/scripts/utils'
// import store from '@/store/index'
// import vue from '@/main'
import Vue from 'vue'
import Router from '@/router'

// /**
//  * 根据场景类型接入对应权限
//  * @param params 当前模块权限相关配置对象
//  */
// export const setUserAuth = function(params) {
//   const { authType, authFn } = params
//   if (authType === 'webAuthLogin') {
//     const corpId = global.APP_CONFIG.corpId
//     const appId = global.APP_CONFIG.appId
//     getWebAuthCode(corpId, appId)
//   }
//   else if (authType === 'scanCodeAuthLogin') {
//     // 扫码授权登录，待接入...
//   }
//   else if (authType === 'customAuthLogin') {
//     authFn && authFn(global.APP_CONFIG)
//   }
// }

// 数据接收
window.addEventListener(
  'message',
  function(e) {
    console.log(e.data)
    if (e.data) {
      try {
        const data = JSON.parse(e.data)
        if (data.method === 'search') {
          if (Router.history.current.name === 'searchList') {
            Router.push({
              name: 'searchList',
              params: {
                keyWord: data.keyword
              },
              query: {
                t: new Date().getTime()
              }
            })
          }
          else {
            Router.push({
              name: 'searchList',
              params: {
                keyWord: data.keyword
                // type: ''
              }
            })
          }
          console.log('素材库进行搜索')
        }
        else if (data.method === 'track') {
          const obj = { ...data.data }
          console.log('埋点数据上报')
          obj.trackData.searchTimeStart = new Date().getTime()
          Vue.prototype._track(obj.trackData)
        }
      }
      catch (error) {
        // console.error('e.data不是json结构 => iframe.js')
      }
    }
  },
  false
)

// 数据发送
export const sendData = function(data) {
  console.log('往iframe发送数据', data)
  window.parent.postMessage(JSON.stringify(data), '*')
}
