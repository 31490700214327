const route = {
  path: '/businessCard/:tenant',
  name: 'businessCard',
  component: () =>
    import(/* webpackChunkName: 'businessCard' */ '@/views/businessCard'),
  redirect: '/businessCard/:tenant/enter',
  meta: { routeName: '顾问名片' },
  children: [
    {
      path: '/businessCard/:tenant/enter',
      name: 'businessCardEnter',
      component: () =>
        import(
          /* webpackChunkName: 'businessCardEnter' */ '@/views/businessCard/enter'
        ),
      meta: { routeName: '顾问名片' }
    },
    {
      path: '/businessCard/:tenant/share',
      name: 'businessCardShare',
      component: () =>
        import(
          /* webpackChunkName: 'businessCardShare' */ '@/views/businessCard/share'
        ),
      meta: { routeName: '顾问名片' }
    }
  ]
}
export default route

