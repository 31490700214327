import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import Vant from 'vant'
import waterfall from 'vue-waterfall2'
import { Lazyload } from 'vant'
import ElementUI from 'element-ui'

// import '@/scripts/cla'
// import '@/scripts/rum'
import 'amfe-flexible'
import '@/scripts/pageAdapt'
import '@/scripts/vconsole'
import '@/scripts/iframe'
import '@/scripts/track'
import 'normalize.css/normalize.css'
import 'vant/lib/index.less'
import '@/assets/styles/vant.less'
import '@/assets/styles/fonts.css'
import '@/assets/less/common.less'
import '@vant/touch-emulator'
import { Toast } from 'vant'

// 全局设置 ElementUI
Vue.use(ElementUI, {
  size: 'small',
  zIndex: 1000
})

Vue.config.ignoredElements = ['wx-open-launch-weapp']
import { Popover } from 'vant'
Vue.use(Vant)
Vue.use(waterfall)
Vue.use(Lazyload)
Vue.use(Popover)

import TopFilter from '@/components/TopFilter'
Vue.use(TopFilter)
import FilterTable from '@/components/BaseTable'
Vue.use(FilterTable)
// 全局挂载lodash
// eslint-disable-next-line no-undef
Vue.prototype.$_ = _
import BaseDialog from '@/components/BaseDialog'
Vue.use(BaseDialog)
import Drawer from '@/components/Drawer'
Vue.use(Drawer)

Vue.mixin({
  methods: {
    thenResolve(res) {
      return new Promise((resolve, reject) => {
        const { code, data, msg } = res
        if (code === 0) {
          resolve(data)
        }
        else {
          Toast({
            message: msg,
            icon: 'warning-o'
          })
          reject(res)
        }
      })
    }
  }
})
Vue.config.productionTip = false
const vue = new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')

export default vue
