/*
 * @Author: chenyc
 * @Date: 2021-09-16 14:55:00
 * @LastEditTime: 2021-09-16 15:22:51
 * @LastEditors: chenyc
 */
import * as types from '../types'
const state = {
  // vdata: [],
  deleteContentIdList: [],
  deleteRuleIdList: []
}

const actions = {
  // 处理发送内容删除id
  setDeletList({ commit, state }, params) {
    commit({
      type: types.SET_DELET_LIST,
      data: params
    })
  },
  // 处理阶段发送内容删除id
  setDeletRuleList({ commit, state }, params) {
    commit({
      type: types.SET_DELET_RULE_LIST,
      data: params
    })
  }
}

const mutations = {
  [types.SET_DELET_LIST](state, { data }) {
    state.deleteContentIdList = data
  },
  [types.SET_DELET_RULE_LIST](state, { data }) {
    state.deleteRuleIdList = data
  }
}

const getters = {
  getdeleteRuleId: state => state.deleteRuleIdList,
  getdeleteList: state => state.deleteContentIdList
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
