const route = [
  {
    path: '/task/list/:tenant',
    name: 'task',
    component: () => import('@/views/task/index'),
    meta: { routeName: '任务中心' }
  },
  {
    path: '/task/create/:tenant/:type',
    name: 'CreatTask',
    component: () => import('@/views/task/create'),
    meta: { routeName: '创建任务' }
  },
  {
    path: '/task/create/:tenant/:type/:taskId',
    name: 'CreatTask',
    component: () => import('@/views/task/create'),
    meta: { routeName: '编辑任务' }
  }
]
export default route
