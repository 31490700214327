const route = [
  {
    path: '/cusGroupChat/:tenant',
    name: 'cusGroupChat',
    component: () => import(/* webpackChunkName: 'cusGroupChat' */ '@/views/cusGroupChat'),
    meta: { routeName: '客户群管理' }
  }, {
    path: '/cusGroupChat/:tenant/detail',
    name: 'cusGroupChatDetail',
    component: () => import(/* webpackChunkName: 'cusGroupChatDetail' */'@/views/cusGroupChat/detail'),
    meta: { routeName: '详情' }
  }, {
    path: '/cusGroupChat/:tenant/updateDetail',
    name: 'cusGroupChatUpdateDetail',
    component: () => import(/* webpackChunkName: 'cusGroupChatDetail' */'@/views/cusGroupChat/updateDetail'),
    meta: { routeName: '编辑' }
  }
]
export default route
