import { getUrlParamsObj } from '@/scripts/utils'

const env = process.env.VUE_APP_ENV || 'test'

const ENV_MAP_ALL = {
  tsmbu: {
    test: {
      120010: { // key为租户id
        corpId: 'ww754a162f2fe3481f', // 企微应用在此配置企业id
        appId: '1000060', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://iapi.sdm.qq.com'
      }
    },
    uat: {
      120017: { // key为租户id
        corpId: 'ww754a162f2fe3481f', // 企微应用在此配置企业id
        appId: '1000076', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://ptrace.sdm.qq.com'
      }
    },
    prod: {
      120009: { // key为租户id
        corpId: 'ww754a162f2fe3481f', // 企微应用在此配置企业id
        appId: '1000069', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://api.sdm.qq.com'
      }
    }
  },
  gm: {
    test: {
      120010: { // key为租户id
        corpId: 'ww754a162f2fe3481f', // 企微应用在此配置企业id
        appId: '1000060', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://iapi.sdm.qq.com'
      }
    },
    uat: {
      120017: { // key为租户id
        corpId: 'ww754a162f2fe3481f', // 企微应用在此配置企业id
        appId: '1000076', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://ptrace.sdm.qq.com'
      }
    },
    prod: {
      120009: { // key为租户id
        corpId: 'ww754a162f2fe3481f', // 企微应用在此配置企业id
        appId: '1000069', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://api.sdm.qq.com'
      }
    }
  },
  audi: {
    test: {
      1001: { // key为租户id
        corpId: 'ww8a53bac7a8929400', // 企微应用在此配置企业id
        appId: '1000061', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://tmta.astro.nxengine.com'
      }
    },
    uat: {
      1001: { // key为租户id
        corpId: 'ww702fcb8401af3b96', // 企微应用在此配置企业id
        appId: '1000045', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://umta.astro.nxengine.com'
      }
    },
    prod: {
      1001: { // key为租户id
        corpId: 'ww8bf950647a21fd2f', // 企微应用在此配置企业id
        appId: '1000073', // 企微应用在此配置应用id（提供企微后台中该应用的agentId和secret让运维生成）,活动签到
        mtaUrl: 'https://mta.astro.saic-audi.mobi'
      }
    }
  }
}

export const ENV_MAP = ENV_MAP_ALL[process.env.VUE_APP_PROJ]

const notInQw = self !== top || getUrlParamsObj().notInQw // 嵌入iframe或者分享出去的详情落地页都免去其为鉴权

const auth_type = (process.env.NODE_ENV === 'development' ? true : notInQw) ? 'customAuthLogin' : 'webAuthLogin' // webAuthLogin:网页授权登录接入企微， scanCodeAuthLogin:扫码授权登录接入企微， customAuthLogin:自定义登录

JSCONFIG['vSearch'] = JSCONFIG['vSearch'] || {}
global.APP_CONFIG = {
  auth_type,
  ...global.APP_CONFIG,
  ...ENV_MAP[env][global.APP_CONFIG.tenantId],
  ...JSCONFIG['vSearch'][global.APP_CONFIG.tenantId] // ConfigMap中的配置key为”vSearch“，可以将相关配置放在ConfigMap
}

console.log(
  'APP_CONFIG::vSearch::',
  process.env.VUE_APP_ENV,
  env,
  global.APP_CONFIG,
  window.JSCONFIG
)
console.log('window.JSCONFIG', window.JSCONFIG)
