<template>
  <div>
    <el-input
      :value="name"
      placeholder="请输入"
      clearable
      @clear="clear"
      @focus="showAlert">
    </el-input>
    <BaseDialog :visible.sync="showDialog" :title="dialogObj.title" width="600px" destroy-on-close :container-style="{ height: 'calc(100% - 60px)', overflow: 'auto', paddingBottom: '30px' }">
      <search-list v-if="showDialog" :default-value="value" :fetch-api="fetchApi" :is-slot='true' :multiple="true" :search-key="'searchName'" :placeholder="'输入群名称或群主进行搜索'" @close='closeDialog' @save='save'>
        <template v-slot:model="model">
          <div class="card">
            <img src="../../assets/img/cusGroup/group.png" alt="" class="avar">
            <div class="cont">
              <div class="groupName">{{model.item.name}}</div>
              <div> 群主：{{model.item.ownerName}}</div>
            </div>
          </div>
        </template>
      </search-list>
    </BaseDialog>
  </div>
</template>
<script>
import SearchList from '@/components/SearchList/index.vue'
export default {
  components: { SearchList },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    fetchApi: {
      type: Function,
      default() {}
    },
    value: {
      type: [String, Number, Array],
      default: ''
    },
    multiple: {
      type: [String, Number, Boolean],
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        value: 'id',
        label: 'name'
      })
    }
  },
  data() {
    return {
      name: '',
      showDialog: false,
      dialogObj: {
        title: '请选择'
      }
    }
  },
  watch: {
    value: {
      handler(val) {
        if (!val || !val.length) {
          this.name = ''
        }
      },
      immediate: true
    }
  },
  methods: {
    showAlert() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    clear() {
      // this.value = ''
      this.$emit('change', [])
    },
    save(val, arr) {
      // console.log(val,arr, 111);
      const { label } = this.options
      if (val && val.length) {
        // this.value = val
        this.name = (arr.map(item => item[label])).join(',')
        this.closeDialog()
        this.$emit('change', val)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.card {
  width: 100%;
  background-color: #fff;
  padding: 5px;
  display: grid;
  align-content: center;
  grid-template-columns: 40px auto;
  .avar {
    width: 36px;
    height: 36px;
    border-radius: 5px;
  }
  .cont {
    display: grid;
    align-content: space-between;
    margin-left: 8px;
    font-size: 12px;
    .groupName {
      font-size: 14px;
    }
  }
}
</style>
