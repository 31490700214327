import axios from 'axios'
import store from '@/store/index'
import { Toast } from 'vant'
import { convertLongInt, getUrlParamsObj } from '@/scripts/utils'
import { setUserAuth } from '@/scripts/userAuth'

global.APP_CONFIG = global.APP_CONFIG || {}
const toast = null

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_PATH || '',
  timeout: 20000,
  responseType: 'text',
  transformResponse: [
    data => {
      return data
    }
  ]
})

const login = () => {
  setUserAuth({
    authType: global.APP_CONFIG.auth_type,
    authFn: () => {}
  })
}
let defaultToken = ''
if (process.env.NODE_ENV === 'development') {
  defaultToken = 'bdc62235-789f-4db2-ae70-cfb3de14db60'
}
service.interceptors.request.use(
  config => {
    config.headers['x-token'] = store.getters['userAuth/getAuthorizeInfo']
      ? store.getters['userAuth/getAuthorizeInfo'].token
      : defaultToken
    // 企微鉴权 47ec15b9-c51d-43d1-9d13-08db117bae75
    // config.headers['userId'] = store.getters['userAuth/getAuthorizeInfo']
    //   ? store.getters['userAuth/getAuthorizeInfo'].qwUserId
    //   : ''
    // Bcenter鉴权
    config.headers['userId'] = store.getters['userAuth/getUsersCurrentInfo']
      ? store.getters['userAuth/getUsersCurrentInfo'].name
      : ''
    config.headers['empId'] = store.getters['userAuth/getUsersCurrentInfo']
      ? store.getters['userAuth/getUsersCurrentInfo'].name
      : ''
    config.headers['name'] = store.getters['userAuth/getUsersCurrentInfo']
      ? encodeURI(store.getters['userAuth/getUsersCurrentInfo'].userName)
      : ''
    // config.headers['iamId'] = store.getters['userAuth/getUsersCurrentInfo']
    //   ? store.getters['userAuth/getUsersCurrentInfo'].id
    //   : ''
    config.headers['orgId'] = store.getters['userAuth/getUsersCurrentInfo']
      ? store.getters['userAuth/getUsersCurrentInfo'].orgId
      : ''
    // config.headers['orgList'] = store.getters['userAuth/getUsersCurrentInfo']
    //   ? store.getters['userAuth/getUsersCurrentInfo'].org
    //   : ''
    if (process.env.VUE_APP_PROJ === 'audi') {
      config.headers['orgList'] = store.getters['userAuth/getUsersCurrentInfo']
        ? store.getters['userAuth/getUsersCurrentInfo'].org
        : ''
      config.headers['roles'] = store.getters['userAuth/getUsersCurrentInfo']
        ? store.getters['userAuth/getUsersCurrentInfo'].roles
        : ''
      // Bcenter鉴权,如果是iframe嵌套则写死默认userId
      const userId =
        self !== top || getUrlParamsObj().notInQw
          ? process.env.VUE_APP_ENV !== 'prod'
            ? process.env.VUE_APP_ENV === 'test'
              ? '13265894707'
              : '1427935316787109888'
            : '1452633981417988096'
          : store.getters['userAuth/getUsersCurrentInfo']
            ? store.getters['userAuth/getUsersCurrentInfo'].name
            : ''
      config.headers['userId'] = userId
    }
    else {
      config.headers['queryActionFlag'] = true
      config.headers['tenantId'] = global.APP_CONFIG.tenantId
      const userId =
        self !== top || getUrlParamsObj().notInQw
          ? process.env.VUE_APP_ENV !== 'prod'
            ? process.env.VUE_APP_ENV === 'test'
              ? '1561601281499635712'
              : '1563051373066756096'
            : '1579390350078025728'
          : store.getters['userAuth/getUsersCurrentInfo']
            ? store.getters['userAuth/getUsersCurrentInfo'].name
            : ''
      config.headers['userId'] = userId
    }

    // if (!config.headers['noSuccToast']) {
    //   toast = Toast.loading({
    //     message: '加载中...',
    //     forbidClick: true
    //   })
    // }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    if (toast) {
      toast.clear()
    }
    console.log('response.data', typeof response.data)
    console.log(response.data)
    const res = JSON.parse(convertLongInt(response.data)) // Long型数值转化
    // 部分接口免鉴权
    if (res.code === 50002 && !['/dapi/auth/v1/app/authorize'].includes(response.config.url)) {
      // 令牌不存在或已失效
      return login()
    }
    else {
      if (((res.code && res.code !== 0) || (res.status && res.status !== 200)) && !response.config.headers['noErrToast']) {
        Toast.fail({
          message: res.msg || res.message,
          duration: 3 * 1000
        })
      }
    }
    return res
  },
  error => {
    console.log('error', error)
    const res = JSON.parse(convertLongInt(error.response.data))
    if (res.code === 50002) {
      // 令牌不存在或已失效
      login()
    }
    if (toast) {
      toast.clear()
    }
    return Promise.reject(error)
  }
)

export function post(url, params, hasFailTip = true) {
  return service.post(url, params).then(
    res => {
      console.log(res, 'post内部res')
      return res
    },
    err => {
      console.log(err, 'post内部err')
      const errObj = JSON.parse(err.response.data)
      if (errObj.status !== 200) {
        Toast.fail({
          message: errObj.message,
          duration: 3 * 1000
        })
      }
      return Promise.reject(err)
    }
  )
}

export function get(url, params, hasFailTip = true) {
  return service({
    method: 'get',
    url: url,
    params
  }).then(
    res => {
      return res
    },
    err => {
      return Promise.reject(err)
    }
  )
}

export function del(url, params, hasFailTip = true) {
  return service({
    method: 'delete',
    url: url,
    params
  }).then(
    res => {
      return res
    },
    err => {
      return Promise.reject(err)
    }
  )
}

// 提供给上传使用
export function upload(url, params) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' }}
  return service.post(url, params, config).then(
    res => {
      return res
    },
    err => {
      return Promise.reject(err)
    }
  )
}

export default service
