/*
 * @Author:
 * @Date: 2019-12-03 13:32:39
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-08-09 13:24:05
 */
import Mock from 'mockjs'

export default Mock.mock({
  code: 1,
  message: '',
  'data|5-20': [
    {
      name: '@cname',
      'price|20-100': 1,
      'number|1-50': 1,
      date: '@datetime'
    }
  ]
})
