/**
 * @Author:
 * @Date: 2019/6/20
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2022-08-10 10:51:27
 */
// app
export const LOGININFO = 'LOGININFO'
export const LOGOUT = 'LOGOUT'
export const NAVLIST = 'NAVLIST'
export const MENULIST = 'MENULIST'
export const TAB_MENU = 'TAB_MENU'
export const STAR_MENU = 'STAR_MENU'
export const COMMON_MENU = 'COMMON_MENU'
export const BUTTON_LOADING = 'BUTTON_LOADING'
export const DICT_DATA = 'DICT_DATA'
export const REGION_DATA = 'REGION_DATA'
export const ADD_CNAME = 'ADD_CNAME'
export const DEL_CNAME = 'DEL_CNAME'
export const ADD_STAR_MENU = 'ADD_STAR_MENU'
export const DEL_STAR_MENU = 'DEL_STAR_MENU'
export const ACTION_DATA = 'ACTION_DATA'
export const NOTIFY_STATE = 'NOTIFY_STATE'

// repairYxw
export const RECEIVABLE_SELECT_INFO_YXW = 'RECEIVABLE_SELECT_INFO_YXW'
export const TAX_RATE_YXW = 'TAX_RATE_YXW'
export const NEW_SPLIT_OBJECT_LIST_YXW = 'NEW_SPLIT_OBJECT_LIST_YXW'
export const ZERO_ELIMINATION_DATA_YXW = 'ZERO_ELIMINATION_DATA_YXW'
export const MAINTENANCE_SYNC_TABLE_YXW = 'MAINTENANCE_SYNC_TABLE_YXW'
export const BOUTIQUE_SYNC_TABLE = 'BOUTIQUE_SYNC_TABLE'
export const BOUTIQUE_PART_SYNC_TABLE = 'BOUTIQUE_PART_SYNC_TABLE'
export const MAINTENANCE_MATERIALS_SYNC_TABLE_YXW = 'MAINTENANCE_MATERIALS_SYNC_TABLE_YXW'
export const SALESMATERIALS_SYNC_TABLE_YXW = 'SALESMATERIALS_SYNC_TABLE_YXW'
export const ADDITIONAL_ITEMS_SYNC_TABLE_YXW = 'ADDITIONAL_ITEMS_SYNC_TABLE_YXW'
export const NET_RECEIPTS_SYNC_TABLE_YXW = 'NET_RECEIPTS_SYNC_TABLE_YXW'
export const RECEIVABLE_SYNC_TABLE_YXW = 'RECEIVABLE_SYNC_TABLE_YXW'
export const RESET_STATE = 'RESET_STATE'
export const OTHER_AMOUNT_OBJ = 'OTHER_AMOUNT_OBJ'

// reception
export const RECEPTION_LIST = 'RECEPTION_LIST'
export const STUFF_LIST = 'STUFF_LIST'
export const TOOL_LIST = 'TOOL_LIST'
export const SALE_LIST = 'SALE_LIST'
export const ADDI_LIST = 'ADDI_LIST'
export const ADVICE_LIST = 'ADVICE_LIST'

// auth
export const SET_DELET_LIST = 'SET_DELET_LIST'
export const SET_DELET_RULE_LIST = 'SET_DELET_RULE_LIST'
