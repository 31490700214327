// 准备移除
import { mapState } from 'vuex'
// import { queryConsultant, getUserInfoByRolePosition } from '@/api';
// import { getOwnerCode } from '@/assets/js/auth';

export const authority = {
  computed: {
    ...mapState('app', ['navList']),
    auths() {
      const target = this.deepFind(this.navList, this.$route.path) || {}
      return target.permission || []
    },
    authsDisabled() {
      // 根据权限判断按钮是否禁用
      const target = this.deepFind(this.navList, this.$route.path) || {}
      return !!target.permission
    },
    getmenuId() {
      const target = this.deepFind(this.navList, this.$route.path) || {}
      return target.id || ''
    }
  },
  methods: {
    getButtonAuthByApi(auths, mark) {
      if (!auths) return true
      return auths.includes(mark.trim())
    },
    deepFind(arr, mark) {
      let res = null
      for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i].children)) {
          res = this.deepFind(arr[i].children, mark)
        }
        if (res !== null) {
          return res
        }
        if (arr[i].key === mark) {
          res = arr[i]
          break
        }
      }
      return res
    }
    /*
     * isremoval: 去重 不去重0 去重1
     * roleType: 职位code
     */
    // async getConsultant(isremoval, roleType, menuId) {
    //   const data = {
    //     menuId: menuId || this.getmenuId,
    //     positionCode: roleType || localStorage.getItem('roleType') || ''
    //   };
    //   const res = await queryConsultant(data);
    //   // console.log('销售顾问', res)
    //   if (res.resultCode === 200) {
    //     let arr = res.data.map(x => ({ value: x.userId, text: x.userName, orgId: x.orgId }));
    //     if (isremoval) {
    //       var obj = {};
    //       arr = arr.reduce(function(item, next) {
    //         obj[next.value] ? '' : (obj[next.value] = true && item.push(next));
    //         return item;
    //       }, []);
    //     }
    //     return arr;
    //   } else {
    //     return [];
    //   }
    // },
    // async getUserInfoByRolePosition(isremoval, roleType) {
    //   const params = {
    //     positionCode: roleType,
    //     ownerCode: getOwnerCode()
    //   };
    //   const res = await getUserInfoByRolePosition(params);
    //   if (res.resultCode === 200) {
    //     let arr = res.data.map(x => ({ value: x.userId, text: x.employeeName, orgId: x.orgId }));
    //     if (isremoval) {
    //       var obj = {};
    //       arr = arr.reduce(function(item, next) {
    //         obj[next.value] ? '' : (obj[next.value] = true && item.push(next));
    //         return item;
    //       }, []);
    //     }
    //     return arr;
    //   } else {
    //     return [];
    //   }
    // }
  }
}
