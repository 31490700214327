/*
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-08-04 17:59:51
 * @LastEditTime: 2022-08-09 20:56:45
 * @FilePath: /scrm-h5/src/views/imageAndText/route.js
 */
const route = [
  {
    path: '/imageAndText/:id/:statisticsId',
    name: 'imageAndText',
    component: () => import('@/views/imageAndText/index'),
    meta: { routeName: '图文', unGetUser: true }
  },
  {
    path: '/imageAndText/:id',
    name: 'imageAndText',
    component: () => import('@/views/imageAndText/index'),
    meta: { routeName: '图文', unGetUser: true }
  }
]
export default route
