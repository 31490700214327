<template>
  <div id="app" @click="_showConsole">
    <router-view></router-view>
  </div>
</template>

<script>
// import Cla from 'cla-web'
import Aegis from 'aegis-web-sdk'

export default {
  name: 'App',

  created() {
    // new Cla({
    //   appname: 'scrm-h5',
    //   baseUrl: process.env.VUE_APP_CLA,
    //   blacklist: ['aegis.qq.com']
    // })
    if (process.env.VUE_APP_PROJ === 'audi') {
      new Aegis({
        id: 'olD0gVNnGYpWMdWKv1', // 上报 id
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        spa: true // spa 应用页面跳转的时候开启 pv 计算
      })
    }
    this.getPlatform()
  },

  methods: {
    getPlatform() {
      const ua = navigator.userAgent
      const isAnd = ua.includes('Android') || ua.includes('Adr')
      const ios = !!ua.match(/\(i[^]+( U)? CPU.+Mac OS X/)
      const isMac =
        navigator.platform === 'Mac68K' ||
        navigator.platform === 'MacPPC' ||
        navigator.platform === 'Macintosh' ||
        navigator.platform === 'MacIntel'
      const isWin =
        navigator.platform === 'Win32' || navigator.platform === 'Windows'
      const isUnix = navigator.platform === 'X11' && !isWin && !isMac
      const isLinux = String(navigator.platform).includes('Linux')
      ios ? localStorage.setItem('OS', 'iPhone') : ''
      isAnd ? localStorage.setItem('OS', 'Android') : ''
      isMac ? localStorage.setItem('OS', 'Mac') : ''
      isWin ? localStorage.setItem('OS', 'Windows') : ''
      isUnix ? localStorage.setItem('OS', 'Unix') : ''
      isLinux ? localStorage.setItem('OS', 'Linux') : ''
    }
  }
}
</script>
<style lang="less" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background: #f5f7fa;
}
</style>
