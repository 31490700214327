import Vue from 'vue'
import VueRouter from 'vue-router'
import { getUser } from '@/scripts/userAuth'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_PATH || '',
  mode: 'history',
  scrollBehavior(_to, _from, _savedPosition) {
    return _savedPosition || { x: 0, y: 0 }
  },
  routes: []
})

// 向router.meta中注入key-value
const injectFlagToRouter = function(router, key, value) {
  router.meta = router.meta || {}
  router.meta[key] = value
  if (router.children) {
    router.children.map(child => {
      child.meta[key] = value
    })
  }
}

/**
 * 自动集合views下模块路由配置
 */
const setRoute = function() {
  const requireRouter = process.env.VUE_APP_PROJ === 'audi' ? require.context('@/views/vSearch', true, /route\.js$/) : require.context('@/views', true, /route\.js$/)
  requireRouter.keys().forEach(fileName => {
    const routerConfig = requireRouter(fileName) // fileName为文件路径名
    if (routerConfig.default) {
      // 动态添加路由
      if (process.env.VUE_APP_PROJ !== 'audi' && !Array.isArray(routerConfig.default)) {
        injectFlagToRouter(routerConfig.default, 'module', fileName.replace(/\.\/(.*)\/route\.js/, '$1'))
        router.addRoute(routerConfig.default)
        router.options.routes.push(routerConfig.default)
      }
      if (process.env.VUE_APP_PROJ !== 'audi' && Array.isArray(routerConfig.default)) {
        routerConfig.default.forEach(item => {
          injectFlagToRouter(item, 'module', fileName.replace(/\.\/(.*)\/route\.js/, '$1'))
          router.addRoute(item)
          router.options.routes.push(item)
        })
      }
      if (process.env.VUE_APP_PROJ === 'audi') {
        router.addRoute(routerConfig.default)
        router.options.routes.push(routerConfig.default)
      }
    }
  })
  const notFound = {
    path: '*',
    name: 'notfound',
    component: () => import('@/components/404')
  }
  router.addRoute(notFound)
  router.options.routes.push(notFound)
}

setRoute()

router.beforeEach(async(to, _from, next) => {
  const moduleName = to.meta.module
  global.APP_CONFIG.tenantId = to.params.tenant || 1001
  if (moduleName === 'actpage') {
    next()
    return
  }
  if (moduleName) {
    require(`@/views/${moduleName}/constant.js`)
  }
  document.title = to.meta.routeName || ''
  if (!to.meta.unGetUser) {
    await getUser()
  }
  next()
})

export default router
