const route = {
  path: '/activity',
  name: 'activity',
  component: () => import(/* webpackChunkName: 'activity' */ '@/views/activity'),
  redirect: '/activity/list',
  meta: { routeName: '活动' },
  children: [
    {
      path: '/activity/list',
      name: 'activityList',
      component: () => import(/* webpackChunkName: 'activity' */'@/views/activity/list'),
      meta: { routeName: '活动列表' }
    },
    {
      path: '/activity/detail',
      name: 'activityDetail',
      component: () => import(/* webpackChunkName: 'activity' */'@/views/activity/detail'),
      meta: { routeName: '活动详情' }
    }
  ]
}
export default route
