const route = {
  path: '/selfPoster/:tenant',
  name: 'selfPoster',
  component: () => import(/* webpackChunkName: 'selfPoster' */ '@/views/selfPoster'),
  redirect: '/selfPoster/:tenant/list',
  meta: { routeName: '自制海报' },
  children: [
    {
      path: '/selfPoster/:tenant/list',
      name: 'selfPosterList',
      component: () => import(/* webpackChunkName: 'selfPosterList' */'@/views/selfPoster/list'),
      meta: { routeName: '海报列表' }
    },
    {
      path: '/selfPoster/:tenant/edit',
      name: 'selfPosterEdit',
      component: () => import(/* webpackChunkName: 'selfPosterEdit' */'@/views/selfPoster/edit'),
      meta: { routeName: '编辑海报' }
    }
  ]
}
export default route
