export const Route = {
  path: '/vSearch/:tenant',
  name: 'vSearch',
  component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch'),
  redirect: '/vSearch/:tenant/home',
  meta: { routeName: '知识库搜索' },
  children: [
    {
      path: '/vSearch/:tenant/home',
      name: 'vSearchHome',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/vSearchHome'),
      meta: { routeName: 'VSearch首页', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/searchList',
      name: 'searchList',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/searchList'),
      meta: { routeName: '搜索列表', unGetUser: true, keepAlive: true }
    },
    {
      path: '/vSearch/:tenant/speechArtDetail/:id',
      name: 'speechArtDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/speechArtDetail'),
      meta: { routeName: '话术详情', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/articleDetail/:id',
      name: 'articleDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/articleDetail'),
      meta: { routeName: '图文详情', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/fileDetail/:id',
      name: 'fileDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/fileDetail'),
      meta: { routeName: '文件详情', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/graphicDetail/:id',
      name: 'graphicDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/graphicDetail'),
      meta: { routeName: '图片详情', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/posterDetail/:id',
      name: 'posterDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/posterDetail'),
      meta: { routeName: '海报详情', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/videoDetail/:id',
      name: 'videoDetail',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/videoDetail'),
      meta: { routeName: '视频详情', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/multiSelectList',
      name: 'multiSelectList',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/multiSelectList'),
      meta: { routeName: '发送的内容', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/userCenterHome',
      name: 'userCenterHome',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/userCenterHome'),
      meta: { routeName: '个人主页', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/userSpeechArt',
      name: 'userSpeechArt',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/userSpeechArt'),
      meta: { routeName: '个人话术', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/speechArtEdit',
      name: 'speechArtEdit',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/views/vSearch/userSpeechArt/speechArtEdit'),
      meta: { routeName: '话术编辑', unGetUser: true }
    },
    {
      path: '/vSearch/:tenant/posterMake/:id',
      name: 'posterMake',
      component: () => import(/* webpackChunkName: 'vSearch' */ '@/components/PosterMake'),
      meta: { routeName: '海报制作', unGetUser: true }
    }
  ]
}
