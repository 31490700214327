import service from '@/api'

/**
 * 获取素材分组
 */
export const getMaterialCategory = params =>
  service.post('/dapi/b-access-layer/v1/access/knowledge-config-center/v1/group/list', params)

/**
 * 获取素材列表
 */
export const getMaterial = params =>
  service.post('/dapi/b-access-layer/v1/access/template-center/material/pageList', params)

/**
 * 获了图文素材
 */
export const getLinkMaterial = params =>
  service.post('/dapi/b-access-layer/v1/access/content-management/content/list2C', params)
/**
 *素材分享上报埋点
 */
export const pushSharePoint = params =>
  service.post('/dapi/b-access-layer/v1/access/content-statistic/material/statistics/add', params)
