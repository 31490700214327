<template>
  <el-date-picker
    v-model="date"
    :type="type"
    :value-format="valueFormat"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :default-time="defaultTime"
    clearable
    :disabled="disabled"
    :picker-options="pickerOptions"
    @change="changeTime"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    defaultTime: {
      type: Array,
      default: () => ['00:00:00', '23:59:59']
    },
    type: {
      type: String,
      default: 'daterange'
    },
    valueFormat: {
      type: String,
      default: 'timestamp'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: [String, Number],
      default: ''
    },
    maxDate: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const that = this
    console.log()
    return {
      date: this.value,
      pickerOptions: {
        disabledDate(date1, date2) {
          const { minDate, maxDate } = that
          // 目前只支持最小时间
          if (date1 && date2 && minDate && maxDate) {
            // 时间要控制在一个范围内时
            return that.getDateTime(date1) > that.getDateTime(minDate) || that.getDateTime(date2) < that.getDateTime(maxDate)
          }
          else {
            if (date1 && minDate) {
              return that.getDateTime(date1) < that.getDateTime(minDate)
            }
            if (date2 && maxDate) {
              return that.getDateTime(date2) > that.getDateTime(maxDate)
            }
          }
        }
      }
    }
  },
  watch: {
    value(val) {
      if (this.date !== val) {
        this.date = val
      }
    }
  },
  methods: {
    changeTime(val) {
      this.$emit('change', val)
    },
    getDateTime(data) {
      return new Date(data).getTime()
    }
  }
}
</script>
