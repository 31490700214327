<template>
  <div>
    <div>
      <el-input v-model="searchValue" :placeholder="placeholder" clearable @keyup.enter.native="onSearch" @clear="onSearch" />
    </div>
    <div v-infinite-scroll="load" class="list">
      <template v-if="list && list.length">
        <el-checkbox-group v-if="multiple" v-model="activeVal">
          <template v-for="(item,index) in list">
            <div :key="index" class="li">
              <el-checkbox :label="item[options.value]">
                <div v-if="!isSlot">
                  <div v-if="itemNode" v-html="itemNode(item)"></div>
                  <div v-else>{{item[options.name]}}</div>
                </div>
                <slot v-else name='model' :item='item'>{{item[options.name]}}</slot>
              </el-checkbox>
            </div>
          </template>
        </el-checkbox-group>
        <el-radio-group v-else v-model="activeVal">
          <template v-for="(item,index) in list">
            <div :key="index" class="li">
              <el-radio :label="item[options.value]">
                <div v-if="!isSlot">
                  <div v-if="itemNode" v-html="itemNode(item)"></div>
                  <div v-else>{{item[options.name]}}</div>
                </div>
                <slot v-else name='model' :item='item'>{{item[options.name]}}</slot>
              </el-radio>
            </div>
          </template>
        </el-radio-group>
      </template>
      <div v-if="finished" style="text-align:center;color:#999;font-size:10px">暂无更多数据</div>
    </div>
    <div class="editformBtn" :style="{ position: 'absolute',left: 0, bottom: 0,right: 0,zIndex: 9,borderTop: '1px solid #e9e9e9',padding: '10px 20px', background: '#fff',textAlign: 'right',}">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="saveDialog">确 定</el-button>
    </div>
  </div>
</template>
<script>
import { debounce } from '@/utils/utils'
export default {
  props: {
    searchKey: {
      type: String,
      default: 'name'
    },
    options: {
      type: Object,
      default: () => ({
        value: 'id',
        name: 'name'
      })
    },
    itemNode: {
      type: [Boolean, Function],
      default: false
    },
    fetchApi: {
      type: Function,
      default() { }
    },
    pageSize: {
      type: Number,
      default: 20
    },
    multiple: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: [String, Array, Number],
      default: ''
    },
    isPage: {
      // 是否是分页数据
      type: Boolean,
      default: true
    },

    isSlot: {
      // 是否需要插槽
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请输入'
    }
  },
  data() {
    return {
      page: {
        pageNum: 0,
        pageSize: this.pageSize
      },
      list: [],
      searchValue: '',
      finished: false,
      activeVal: '',
      listAll: [] // 所有列表接口数组拼接
    }
  },
  watch: {
    defaultValue: {
      handler(val) {
        this.activeVal = val
      },
      immediate: true
    }
  },
  created() {
    // this.getFetchApi()
  },
  methods: {
    init() {
      this.page = {
        pageNum: 0,
        pageSize: this.pageSize
      }
      this.finished = false
    },
    load: debounce(function() {
      if (this.finished) return
      this.page.pageNum++
      this.getFetchApi()
    }, 500),
    async getFetchApi() {
      const { pageNum, pageSize } = this.page
      if (this.isPage) {
        const data = await this.fetchApi({
          pageNum,
          pageSize,
          paramData: {
            [this.searchKey]: this.searchValue
          }
        }).then(this.thenResolve)
        const { dataList, page } = data
        if (pageNum === 1) {
          this.list = dataList
        }
        else {
          this.list = this.list.concat(dataList)
        }
        this.listAll = this.listAll.concat(dataList)
        if (this.list.length >= page.totalCount) {
          this.finished = true
        }
      }
      else {
        const data = await this.fetchApi({
          [this.searchKey]: this.searchValue
        }).then(this.thenResolve)
        this.list = data
      }
    },
    onSearch() {
      this.init()
      this.load()
    },
    closeDialog() {
      this.$emit('close')
    },
    saveDialog() {
      const { activeVal, options, multiple, listAll } = this
      const arr = []
      // 解决bug：先选一个，在模糊搜索后再选择一个，但是输入框中只回显了最后选中的一个
      const res = listAll.filter(function(item, index, self) {
        return self.findIndex(el => el.id === item.id) === index
      })
      if ((!multiple && activeVal) || (multiple && activeVal.length)) {
        res.map(item => {
          if (multiple) {
            if (activeVal.includes(item[options.value])) {
              arr.push(item)
            }
          }
          else {
            if (item[options.value] === activeVal) {
              arr.push(item)
            }
          }
        })
      }
      this.$emit('save', activeVal, arr)
    }
  }
}
</script>
<style lang="less" scoped>
.editformBtn {
  text-align: right;
}
.list {
  overflow-y: auto;
  height: 300px;
  box-sizing: border-box;
  padding: 10px;
  .li {
    margin-bottom: 10px;
    /deep/ label {
      display: flex;
      align-items: center;
    }
  }
}
</style>
