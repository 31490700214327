import Vue from 'vue'

/**
 * URL进行encode转码
 * @returns {String}} 转码后链接
 */
export const urlEncode = function(url) {
  const resUrl = url
    .replace(/[/]/g, '%2f')
    .replace(/[:]/g, '%3a')
    .replace(/[#]/g, '%23')
    .replace(/[&]/g, '%26')
    .replace(/[=]/g, '%3d')
  return resUrl
}

/**
 * 获取URL参数
 * @returns {Object} 参数对象
 */
export const getUrlParamsObj = function() {
  var url = location.search // 获取url中"?"符后的字串
  var theRequest = {}
  if (url.indexOf('?') !== -1) {
    var str = url.substr(1)
    const strs = str.split('&')
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1])
    }
  }
  return theRequest
}

export const delUrlParams = function(url, params) {
  params.map(item => {
    const regexp = new RegExp(`&?${item}=[^&]*`, 'g')
    url = url.replace(regexp, '')
  })
  if (/.*\?$/.test(url)) {
    // 去除末尾的?
    url = url.replace('?', '')
  }
  return url
}

// 转化长整型，避免存到js中丢失精度
// 超过16位的数值型都将替换为字符串
export function convertLongInt(str) {
  return str.replace(/:(\d{16,})/g, ':"$1"')
}

/**
 * 判断是否是微信客户端（包括ios/android/mac os/windows）
 */
export function isWxwork() {
  const ua = window.navigator.userAgent
  return ua.includes('wxwork')
}

export function isDevtools() {
  const ua = window.navigator.userAgent
  return ua.includes('wechatdevtools')
}

export function sleep(timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

/**
 * 关键词标色
 */
export function keywordsMarkColor(content = '', keywords = '', color = '') {
  const arr = content.split(keywords)
  const colorKeywords = `<span class="keywords-mark-${color}">${keywords}</span>`
  return arr.join(colorKeywords)
}

/**
 *去掉首尾空格
 */
Vue.prototype._trim = function(str = '') {
  return str.replace(/(^\s*)|(\s*$)/g, '')
}

/**
 *判断企业微信软件所在UA
 */
Vue.prototype._userAgentType = function() {
  let platform = ''
  let system = ''
  const userAgent = window.navigator.userAgent
  if (userAgent.toLowerCase().indexOf('mobile') === -1) {
    platform = 'pc'
  }
  else {
    platform = 'mobile'
  }
  if (userAgent.indexOf('iPhone') !== -1) {
    system = 'iPhone'
  }
  else if (userAgent.indexOf('Android') !== -1) {
    system = 'Android'
  }
  else if (userAgent.indexOf('Linux') !== -1) {
    system = 'Linux'
  }
  else if (userAgent.indexOf('Windows') !== -1) {
    system = 'Windows'
  }
  else if (userAgent.indexOf('Macintosh') !== -1) {
    system = 'Macintosh'
  }
  return { platform, system }
}

/**
 *判断网络状态，后面可扩展网速检查，根据网络情况对内容做按需展示提升访问速度
 */
Vue.prototype._isNetWorkOnLine = function(url) {
  // 步骤一:创建异步对象
  // var ajax = new XMLHttpRequest()
  // // 步骤二:设置请求的url参数,参数一是请求的类型,参数二是请求的url,可以带参数,动态的传递参数starName到服务端
  // ajax.open(
  //   'get',
  //   'https://astro-zt-test-bcenter-1304913914.cos.ap-shanghai.myqcloud.com/img/empty.ddf6811a09.png'
  // )
  // // 步骤三:发送请求
  // ajax.send()
  // // 步骤四:注册事件 onreadystatechange 状态改变就会调用
  // ajax.onreadystatechange = function() {
  //   if (ajax.readyState === 4 && ajax.status === 200) {
  //     // 步骤五 如果能够进到这个判断 说明 数据 完美的回来了,并且请求的页面是存在的
  //     console.log(ajax.responseText) // 输入相应的内容
  //   }
  //   console.log(ajax.readyState, ajax.status)
  // }
  // console.log(11111, ajax.onerror)
  return navigator.onLine
}

/**
 *过滤标签中标签名
 */
Vue.prototype.filterLabels = function(str = '') {
  str = str
    .split('<em>')
    .join('')
    .split('</em>')
    .join('')
  return str
}
