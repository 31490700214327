/*
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-11-14 10:06:16
 * @LastEditTime: 2022-11-14 10:06:39
 * @FilePath: /scrm-h5/src/views/cusIntention/route.js
 */
const route = [
  {
    path: '/cusIntention/:tenant',
    name: 'cusIntention',
    component: () => import(/* webpackChunkName: 'cusGroupChat' */ '@/views/cusIntention'),
    meta: { routeName: '客户意向' }
  }
]
export default route
