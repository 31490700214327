import service from '@/api'

const proj = process.env.VUE_APP_PROJ

// 获取用户登录凭证token
export const getAuthorizeInfo = (params) => {
  params = params || {}
  params.tenantId = global.APP_CONFIG.tenantId
  if (proj === 'audi') {
    return service.get('/wxapi/auth/v1/app/authorize', { headers: { noSuccToast: false }, params })
  }
  return service.get('/dapi/auth/v1/app/authorize', { headers: { noSuccToast: false }, params })
}

// 获取当前用户信息
export const getUsersCurrentInfo = async() => {
  if (proj === 'audi') {
    return service.get('/wxapi/auth/v1/app/user', { headers: { noSuccToast: true }})
  }
  return service.get('/dapi/auth/v1/app/user', { headers: { noSuccToast: true }})
}

//  获取JSAPI签名信息
export const getJssdkConfig = (params) => {
  params = params || {}
  params.tenantId = global.APP_CONFIG.tenantId
  params.appId = global.APP_CONFIG.appId
  if (proj === 'audi') {
    return service.post('/wxapi/qw-proxy-gateway/v1/ticket/app/web/jsapi-sign', params, { headers: { noSuccToast: true }})
  }
  return service.post('/dapi/qw-proxy-gateway/wxwork/v1/ticket/app/web/jsapi-sign', params, { headers: { noSuccToast: true }})
}
